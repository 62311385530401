import { Button, Skeleton, Tooltip } from "@clockwise/design-system";
import { Edit } from "@clockwise/design-system/icons";
import { Loader } from "@clockwise/design-system/src/components/Loader";
import { Check, Close } from "@clockwise/icons";
import { TextField } from "@material-ui/core";
import classNames from "classnames";
import { debounce } from "lodash";
import React, {
  ChangeEvent,
  FormEvent,
  MouseEvent,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from "react";
import { WarningPopover } from "./WarningPopover";

export type Props = {
  username: string;
  loading: boolean;
  onSaveUsername: (userName: string) => Promise<void>;
  onValidateUsername: (userName: string) => Promise<void>;
  validating?: boolean;
  error?: string;
  initialEditing?: boolean; // Testing property
  alwaysEditing?: boolean;
};

export default function UsernameEditor({
  username,
  loading,
  onSaveUsername,
  onValidateUsername,
  validating = false,
  error,
  initialEditing = false,
  alwaysEditing = false,
}: Props) {
  const [newUsername, setNewUsername] = useState(username);
  const [editing, setEditing] = useState(initialEditing);
  const [saving, setSaving] = useState(false);

  // Make sure the username in the input is kept in sync with the username external to this
  // component.
  useEffect(() => {
    setNewUsername(username);
  }, [username]);

  const onEdit = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setEditing(true);
  };

  // Using memo instead of callback so ESLint can read the dependencies.
  const debouncedValidate = useMemo(() => debounce(onValidateUsername, 500), [onValidateUsername]);

  const hasError = !!error;

  const onChangeUsername = async (name: string) => {
    setNewUsername(name);
    await debouncedValidate(name);
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    void onChangeUsername(e.target.value);
  };

  const onSave = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      setSaving(true);
      await onSaveUsername(newUsername); // TODO (lsanwick) Handle response value here.
      setEditing(false);
      setSaving(false);
    } catch (e) {
      setSaving(false);
      // TODO (lsanwick) Show an error message here.
    }
  };
  const onCancel = async () => {
    // Reset name to original value
    await onChangeUsername(username);
    setEditing(false);
  };

  const hasChanged = username !== newUsername;

  const warningTooltip = (
    <div className="cw-flex cw-flex-col cw-gap-1">
      <div className="cw-body-base cw-text-warning cw-font-bold">
        Links with your old username will stop working
      </div>
      <div>
        Update your links anywhere you are using them. This could be your email signature, or any
        link you've already sent out where the recipient hasn't booked yet.
      </div>
      <div className="cw-text-destructive cw-flex cw-items-center cw-gap-1">
        <Close className="cw-text-20" />
        <span className="cw-line-through cw-text-wrap cw-w-[calc(100%-20px)]">
          https://getclockwise.com/c/{username}/quick-meeting
        </span>
      </div>
      <div className="cw-flex cw-items-center cw-gap-1">
        <Check className="cw-text-20 cw-text-positive" />
        <span className="cw-text-wrap cw-w-[calc(100%-20px)]">
          https://getclockwise.com/c/{newUsername}/quick-meeting
        </span>
      </div>
    </div>
  );

  return (
    <form onSubmit={onSave}>
      <div className="cw-flex cw-gap-2 cw-items-center cw-flex-wrap cw-min-h-[39px]">
        {alwaysEditing || editing ? (
          <>
            <UsernameEditMode
              autoFocus={!alwaysEditing}
              warningTooltip={warningTooltip}
              hasChanged={hasChanged}
              username={newUsername}
              onChange={onChange}
              hasError={hasError}
              error={error}
              validating={validating}
            />
            {(!alwaysEditing || hasChanged) && (
              <div className="cw-flex cw-gap-2">
                <Button
                  type="submit"
                  variant="text"
                  sentiment="positive"
                  disabled={validating || saving || hasError || !hasChanged}
                >
                  Save
                </Button>
                <Button variant="text" onClick={onCancel} disabled={saving}>
                  Cancel
                </Button>
              </div>
            )}
          </>
        ) : (
          <>
            <UsernameDisplayMode username={username} />
            <div className="cw-mt-1">
              <Button
                startIcon={Edit}
                variant="text"
                sentiment="positive"
                onClick={onEdit}
                cw-id="links-dashboard-edit-username-button"
                disabled={loading}
              >
                Edit Username
              </Button>
            </div>
          </>
        )}
      </div>
    </form>
  );
}

export const UsernameDisplayMode = ({ username }: { username: string }) => {
  return (
    <div className="cw-flex cw-gap-2 cw-items-center cw-max-w-full">
      <Tooltip
        title={
          <span>
            Displayed in links you send people, for example: <br />
            <strong>getclockwise.com/c/{username}/quick-meeting</strong>
          </span>
        }
        arrow
      >
        <div
          className="cw-body-lg cw-text-neutral-muted cw-text-ellipsis cw-overflow-hidden cw-whitespace-nowrap cw-cursor-default cw-flex cw-flex-row cw-items-center"
          cw-id="links-dashboard-link-url"
        >
          @{username || <Skeleton className="cw-ml-0.5 cw-mb-1" width={120} height={30} />}
        </div>
      </Tooltip>
    </div>
  );
};

interface EditModeProps {
  warningTooltip: ReactElement;
  username: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  autoFocus?: boolean;
  hasChanged?: boolean;
  validating?: boolean;
  hasError: boolean;
  error?: string;
}

export const UsernameEditMode = ({
  username,
  onChange,
  warningTooltip,
  error,
  autoFocus = false,
  hasError = false,
  hasChanged = false,
  validating = false,
}: EditModeProps) => {
  return (
    <div className="cw-flex cw-font-body cw-items-center cw-gap-1 cw-bg-neutral-disabled cw-rounded sm:cw-px-2">
      <div className="cw-text-neutral-disabled cw-hidden sm:cw-block">
        https://getclockwise.com/c/
      </div>
      <WarningPopover title={warningTooltip} open={hasChanged}>
        <TextField
          autoFocus={autoFocus}
          aria-label="username"
          spellCheck={false}
          variant="filled"
          value={username}
          onChange={onChange}
          error={hasError}
          helperText={error}
          className={classNames("cw-basis-full sm:cw-basis-auto cw-max-w-[180px]", {
            "sm:cw-h-[39px]": hasError,
          })}
          InputProps={{
            className: "cw-rounded-none",
            endAdornment: validating ? <Loader sentiment="neutral" size="sm" /> : undefined,
          }}
          inputProps={{
            style: {
              paddingTop: "10px",
            },
          }}
        />
      </WarningPopover>
      <div className="cw-text-neutral-disabled cw-hidden sm:cw-block">/quick-meeting</div>
    </div>
  );
};
