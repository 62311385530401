import { Skeleton } from "@clockwise/design-system";
import { getTimeZoneDisplayName } from "@clockwise/web-commons/src/util/time-zone.util";
import React from "react";
import { logger } from "../../util/logger.util";
import useOrgTimeZone from "../hooks/useOrgTimezone";

export const OrgTimeZone = () => {
  const { orgTimeZone, loading, error } = useOrgTimeZone();

  const timeZone = orgTimeZone ? getTimeZoneDisplayName(orgTimeZone) : null;
  const displayText =
    "Clockwise looks for better times for flexible meetings every weekday at 4:00pm in the time zone set for your organization";

  if (error) {
    logger.error(`failed to load OrgTimeZone`, error);
  }

  if (loading) {
    return (
      <div className="cw-mt-6">
        <div className="cw-heading-2xl cw-mb-2">Flexible meeting optimization</div>
        <div className="cw-body-base">
          {displayText}
          <Skeleton width={250} height={20} className="cw-ml-2 cw-inline-block" />
        </div>
      </div>
    );
  }

  return (
    <div className="cw-mt-6">
      <div className="cw-heading-2xl cw-mb-2">Flexible meeting optimization</div>
      <div className="cw-body-base">
        {displayText}
        {timeZone && `: ${timeZone}`}
      </div>
    </div>
  );
};
