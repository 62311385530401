import classNames from "classnames";
import React, { ReactNode } from "react";
import { Profile } from "../../util/profile.util";
import { AvatarCluster } from "./AvatarCluster";
import { Item } from "./Item";
import { ProposalRenderSurface } from "./types";

export const Event: React.FC<{
  profiles: Profile[];
  variant?: ProposalRenderSurface;
  overflowLimit?: 1 | 2 | 3 | 4;
  children: ReactNode;
  spacing?: "Default" | "Compact";
}> = ({ children, profiles, overflowLimit = 4, variant = "AI_CHAT", spacing = "Default" }) => {
  return (
    <Item
      spacing={spacing}
      right={<div className="cw-body-sm">{children}</div>}
      left={
        <div
          className={classNames("cw-flex cw-items-center cw-justify-center", {
            "cw-w-8 cw-h-8": spacing === "Default",
            "cw-w-7 cw-h-7": spacing === "Compact",
            "cw-bg-neutral": variant === "AI_CHAT",
            "cw-bg-default": variant === "SHARED_PROPOSAL" || variant === "MODAL_PROPOSAL",
            "cw-bg-transparent": variant === "EVENT",
          })}
        >
          <AvatarCluster profiles={profiles} overflowLimit={overflowLimit} />
        </div>
      }
    />
  );
};
