import { useCurrentProposal } from "#webapp/src/components/chat-plus-calendar/CurrentProposalContext";
import { RemoveTradeoffFromProposalDocument } from "#webapp/src/components/tradeoffs/__generated__/RemoveTradeoffFromProposal.v2.generated";
import {
  AvailabilityIssue,
  FixableConflict,
  Inconveniences,
  NoIssues,
  WorksForEveryone,
} from "#webapp/src/components/tradeoffs/Tradeoffs";
import { getTradeoffBlocksToDisplay } from "#webapp/src/components/tradeoffs/tradeoffs.utils";
import { Divider } from "@clockwise/design-system";
import { EventAvailable } from "@clockwise/icons";
import { ProposalState, TradeoffType } from "@clockwise/schema/v2";
import { useGatewayMutation } from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { AttendeeAvatar } from "@clockwise/web-commons/src/ui/AttendeeAvatar";
import { AttendeeAvatarStack } from "@clockwise/web-commons/src/ui/AttendeeAvatarStack";
import { TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import { Profile } from "@clockwise/web-commons/src/util/profile.util";
import { getRenderTimeZone } from "@clockwise/web-commons/src/util/time-zone.util";
import classNames from "classnames";
import { isEmpty } from "lodash";
import { Interval } from "luxon";
import pluralize from "pluralize";
import { default as React, useCallback } from "react";
import toast from "react-hot-toast";
import { TradeoffBlock } from "../../../chat-plus-calendar/PersistedProposalContext";
import { formattedDateTime } from "../../../web-app-calendar/calendar-popover/utils/formattedDateTime";

const TIME_OPTIONS_SHARED_CLASSES =
  "cw-border-solid cw-font-medium cw-group cw-border cw-border-subtle cw-px-2.5 cw-py-3 cw-mb-2 cw-rounded-lg cw-flex cw-justify-between hover:cw-bg-busy-inset hover:cw-bg-opacity-50";

const RELEVANT_TRADEOFF_TYPES = new Set([
  TradeoffType.AvailabilityIssue,
  TradeoffType.FixableConflict,
]);

const mapProposalOptionCategoryToColor = (tradeoffType?: TradeoffType): string => {
  switch (tradeoffType) {
    case TradeoffType.FixableConflict:
      return "cw-text-busy";
    case TradeoffType.AvailabilityIssue:
      return "cw-text-warning";
  }

  return "cw-text-subtle";
};

const shouldShowAffectedAttendees = (tradeoffType?: TradeoffType): boolean => {
  if (!tradeoffType) {
    return false;
  }

  return RELEVANT_TRADEOFF_TYPES.has(tradeoffType);
};

const getAffectedAttendees = (tradeoffBlocks: TradeoffBlock[]): Profile[] => {
  const relevantBlocks = tradeoffBlocks.filter((block) =>
    RELEVANT_TRADEOFF_TYPES.has(block.tradeoffType),
  );

  return relevantBlocks
    .flatMap((block) => block.affectedAttendees)
    .map((affectedAttendee) => ({
      givenName: affectedAttendee.person.displayName,
      externalImageUrl: affectedAttendee.person.externalImageUrl,
    }));
};

const getOverridingTradeoffType = (tradeoffBlocks: TradeoffBlock[]): TradeoffType | undefined => {
  if (tradeoffBlocks.length === 0) {
    return undefined;
  }
  if (
    tradeoffBlocks.filter((block) => block.tradeoffType === TradeoffType.AvailabilityIssue).length >
    0
  ) {
    return TradeoffType.AvailabilityIssue;
  }
  if (
    tradeoffBlocks.filter((block) => block.tradeoffType === TradeoffType.FixableConflict).length > 0
  ) {
    return TradeoffType.FixableConflict;
  }
  if (
    tradeoffBlocks.filter((block) => block.tradeoffType === TradeoffType.Inconvenience).length > 0
  ) {
    return TradeoffType.Inconvenience;
  }
  if (
    tradeoffBlocks.filter((block) => block.tradeoffType === TradeoffType.LowVisibilityInconvenience)
      .length > 0
  ) {
    return TradeoffType.LowVisibilityInconvenience;
  }

  return undefined;
};

const getTimeSuggestionsCopy = (tradeoffBlocks: TradeoffBlock[]): string => {
  const tradeoffType = getOverridingTradeoffType(tradeoffBlocks);
  let result = "";

  if (
    tradeoffType !== TradeoffType.AvailabilityIssue &&
    tradeoffType !== TradeoffType.FixableConflict
  ) {
    return "No conflicts";
  }

  const affectedAttendees = getAffectedAttendees(tradeoffBlocks);
  if (tradeoffType === TradeoffType.AvailabilityIssue) {
    result = pluralize("Conflict", affectedAttendees.length);
  } else if (tradeoffType === TradeoffType.FixableConflict) {
    result = pluralize("Fixable Conflict", affectedAttendees.length);
  }

  return result;
};

const TimeSuggestionsContentExpanded = ({
  interval,
  tradeoffBlocks,
  showAttendeeCount = true,
  proposalStatus,
}: {
  interval: Interval;
  tradeoffBlocks: TradeoffBlock[];
  permissions: {
    canModify: boolean;
    canInviteOthers: boolean;
    canSeeOtherGuests: boolean;
  };
  proposalStatus: ProposalState;
  showAttendeeCount?: boolean;
}) => {
  const track = useTracking();
  const [removeTradeoffFromProposal] = useGatewayMutation(RemoveTradeoffFromProposalDocument);

  const handleRemovalOfTradeoff = useCallback(
    (diffId: string) => {
      void removeTradeoffFromProposal({
        variables: { eventDiffId: diffId },
        onCompleted: (data) => {
          toast.success("Will not fix conflict");

          const proposalId = data?.removeTradeoffFromProposal?.id;
          track(TrackingEvents.DIRECT_MANIPULATION.TIME_SUGGESTIONS.TRADEOFF.REMOVED, {
            proposalId,
          });
        },
        onError: () => {
          toast.error("Failed to remove tradeoff");
        },
      });
    },
    [removeTradeoffFromProposal, track],
  );

  const tradeoffType = getOverridingTradeoffType(tradeoffBlocks);
  const { currentProposal } = useCurrentProposal();
  const totalAttendeeCount = currentProposal?.attendees.length ?? 0;

  const filteredTradeoffBlocks = getTradeoffBlocksToDisplay(tradeoffBlocks);
  const uniqueTradeoffAttendees = new Set(
    [...filteredTradeoffBlocks].flatMap((tradeoff) =>
      tradeoff.affectedAttendees.map((attendee) => attendee.person.email),
    ),
  );

  const showWorksForEveryone = isEmpty(filteredTradeoffBlocks);
  const showRemaningNoIssues =
    !showWorksForEveryone && totalAttendeeCount > uniqueTradeoffAttendees.size;

  return (
    <div className={classNames("cw-flex cw-flex-col", "cw-w-full")}>
      <div>
        <div
          className={classNames("cw-body-sm cw-text-default cw-font-semibold", {
            "cw-text-warning": tradeoffType === TradeoffType.AvailabilityIssue,
            "cw-text-busy": tradeoffType === TradeoffType.FixableConflict,
            "cw-text-default": !tradeoffType,
          })}
        >
          {formattedDateTime(interval.start, getRenderTimeZone())}
        </div>
        <Divider spacing="md" />
      </div>
      {showWorksForEveryone && <WorksForEveryone />}
      {filteredTradeoffBlocks.map((tradeoffBlock, index) => {
        const showBottomBorder = showRemaningNoIssues || index < filteredTradeoffBlocks.length - 1;
        switch (tradeoffBlock.tradeoffType) {
          case TradeoffType.AvailabilityIssue:
            return (
              <AvailabilityIssue
                key={index}
                tradeoffBlock={tradeoffBlock}
                showAttendeeCount={showAttendeeCount}
                showBottomBorder={showBottomBorder}
                loading={proposalStatus === ProposalState.LoadingSuggestions}
              />
            );
          case TradeoffType.FixableConflict:
            return (
              <FixableConflict
                key={index}
                tradeoffBlock={tradeoffBlock}
                showAttendeeCount={showAttendeeCount}
                showBottomBorder={showBottomBorder}
                onClickX={handleRemovalOfTradeoff}
                loading={proposalStatus === ProposalState.LoadingSuggestions}
              />
            );
          case TradeoffType.Inconvenience:
            return (
              <Inconveniences
                key={index}
                tradeoffBlock={tradeoffBlock}
                showAttendeeCount={showAttendeeCount}
                showBottomBorder={showBottomBorder}
                loading={proposalStatus === ProposalState.LoadingSuggestions}
              />
            );
          default:
            return null;
        }
      })}
      {showRemaningNoIssues && (
        <NoIssues
          attendeeCount={totalAttendeeCount - uniqueTradeoffAttendees.size}
          showAttendeeCount={showAttendeeCount}
        />
      )}
    </div>
  );
};

const TimeSuggestionsContentCollapsed = ({
  interval,
  tradeoffBlocks,
}: {
  interval: Interval;
  tradeoffBlocks: TradeoffBlock[];
  permissions: {
    canModify: boolean;
    canInviteOthers: boolean;
    canSeeOtherGuests: boolean;
  };
  proposalStatus: ProposalState;
}) => {
  const tradeoffType = getOverridingTradeoffType(tradeoffBlocks);

  return (
    <div className={classNames("cw-flex cw-flex-col", "cw-w-full")}>
      <div className="cw-body-sm  cw-text-default cw-font-medium">
        {formattedDateTime(interval.start, getRenderTimeZone())}
      </div>
      <div className={classNames("cw-flex cw-over cw-body-sm cw-gap-1 cw-items-center")}>
        {shouldShowAffectedAttendees(tradeoffType) ? (
          <AttendeeAvatarStack
            maxShown={3}
            size="medium"
            disableOverflow={true}
            disableAnimation={true}
          >
            {getAffectedAttendees(tradeoffBlocks).map((attendee) => (
              <AttendeeAvatar
                key={attendee.familyName}
                profile={attendee}
                size="medium"
                borderColor={"white"}
              />
            ))}
          </AttendeeAvatarStack>
        ) : (
          <EventAvailable fontSize="inherit" className={classNames("cw-fill-muted")} />
        )}
        <div
          className={classNames(
            mapProposalOptionCategoryToColor(tradeoffType),
            "cw-text-11 cw-pt-s2 cw-font-medium",
          )}
        >
          {getTimeSuggestionsCopy(tradeoffBlocks)}
        </div>
      </div>
    </div>
  );
};

export const TimeSuggestionOption = ({
  isSelected,
  interval,
  tradeoffBlocks,
  onUpdateTime,
  onHoverTimeSuggestion,
  permissions,
  proposalStatus,
}: {
  isSelected: boolean;
  interval: Interval;
  tradeoffBlocks: TradeoffBlock[];
  onChooseTime: () => void;
  onUpdateTime: (interval: Interval) => void;
  onHoverTimeSuggestion: (interval: Interval | null) => void;
  permissions: {
    canModify: boolean;
    canInviteOthers: boolean;
    canSeeOtherGuests: boolean;
  };
  proposalStatus: ProposalState;
}) => {
  const tradeoffType = getOverridingTradeoffType(tradeoffBlocks);

  return (
    <div
      key={interval.start.toString()}
      className={classNames(
        TIME_OPTIONS_SHARED_CLASSES,
        {
          "cw-border-warning": isSelected && tradeoffType === TradeoffType.AvailabilityIssue,
          "cw-border-busy": isSelected && tradeoffType === TradeoffType.FixableConflict,
          "cw-border-positive": isSelected && !tradeoffType,
        },
        "cw-mx-3",
      )}
      style={{
        background:
          isSelected && tradeoffType === TradeoffType.AvailabilityIssue
            ? "linear-gradient(to right, #fffaeb 0%, #fffefa 74%, #ffffff 90%)"
            : isSelected && tradeoffType === TradeoffType.FixableConflict
            ? "linear-gradient(to right, #F7FAFF 0%, #ffffff 100%)"
            : isSelected && !tradeoffType
            ? "linear-gradient(to right, #E0FFE0 0%, #ffffff 100%)"
            : undefined,
      }}
      role="button"
      onClick={() => onUpdateTime(interval)}
      onMouseEnter={() => onHoverTimeSuggestion(interval)}
      onMouseLeave={() => onHoverTimeSuggestion(null)}
    >
      {isSelected ? (
        <TimeSuggestionsContentExpanded
          interval={interval}
          tradeoffBlocks={tradeoffBlocks}
          proposalStatus={proposalStatus}
          permissions={permissions}
        />
      ) : (
        <TimeSuggestionsContentCollapsed
          interval={interval}
          tradeoffBlocks={tradeoffBlocks}
          proposalStatus={proposalStatus}
          permissions={permissions}
        />
      )}
    </div>
  );
};
