import { Skeleton } from "@clockwise/design-system";
import { useUpdateActiveEvent } from "@clockwise/web-commons/src/util/ActiveEventContext";
import { generateRescheduleCQL } from "@clockwise/web-commons/src/util/generateCQL";
import { getRenderTimeZone } from "@clockwise/web-commons/src/util/time-zone.util";
import { isEmpty, random } from "lodash";
import { DateTime, Interval } from "luxon";
import pluralize from "pluralize";
import React, { useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useCurrentOrNextEvent } from "../../../../hooks/useCurrentOrNextEvent/useCurrentOrNextEvent";
import { track, TrackingEvents } from "../../../../util/analytics.util";
import { useProcessMessage } from "../../../chat/ai-chat/hooks/useProcessMessage";
import { useUserProfile } from "../../../hooks/useUserProfile";
import { ButtonProps, CalloutCard } from "./CalloutCard";

const openVideoLink = (url?: string) => {
  window.open(url, "_blank");
};

const allEmptyOptions = [
  { emoji: "😎", text: "Enjoy some you time" },
  { emoji: "🏖️", text: "Your time is all yours" },
  { emoji: "☕️", text: "Time to treat yourself" },
  { emoji: "🐶", text: "Go for a walk or something" },
  { emoji: "🦆", text: "Take a breather" },
];

const getMeetingStartText = (startTime: DateTime) => {
  const now = DateTime.now();
  const diffTotalMinutes = startTime.diff(now, ["minutes"]).minutes;
  const diffhours = Math.floor(Math.abs(diffTotalMinutes / 60));
  const diffMinutes = Math.floor(Math.abs(diffTotalMinutes % 60));
  if (diffTotalMinutes === 0 || (diffhours === 0 && diffMinutes === 0)) {
    return `Starting now`;
  } else if (diffTotalMinutes < 0) {
    return `Started ${diffhours ? `${diffhours} ${pluralize("hour", diffhours)}` : ""} ${
      diffMinutes ? `${diffMinutes} ${pluralize("minute", diffMinutes)}` : ""
    } ago`;
  } else {
    return `In ${diffhours ? `${diffhours} ${pluralize("hour", diffhours)}` : ""} ${
      diffMinutes ? `${diffMinutes} ${pluralize("minute", diffMinutes)}` : ""
    }`;
  }
};

const formatIntervalDuration = (interval: Interval) => {
  const duration = interval.toDuration();
  const hours = duration.as("hours");
  const minutes = duration.as("minutes");

  if (hours >= 1) {
    return `${hours.toFixed(1)}hr`;
  } else if (minutes >= 1) {
    // avoids "0min" if thats possible
    return `${Math.floor(minutes)}min`;
  } else {
    return null;
  }
};

export const AINextMeetingLink = () => {
  const { processMessage } = useProcessMessage();
  const { userProfile } = useUserProfile();
  const setActiveEvent = useUpdateActiveEvent();
  const { event: eventToShow, loading } = useCurrentOrNextEvent();
  const [_, setSeconds] = useState(0);

  useEffect(() => {
    // To update the time every 10 seconds, this causes the component to re-render
    const interval = setInterval(() => {
      setSeconds((seconds) => seconds + 1);
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  const randomInteger = useMemo(() => {
    return random(0, allEmptyOptions.length - 1);
  }, []);

  if (loading) {
    return <Skeleton height={110} />;
  }

  if (!eventToShow) {
    return (
      <div>
        <div className="cw-body-base cw-text-12 cw-text-subtle cw-font-semibold cw-mt-2">
          No upcoming meetings today
        </div>
        <div className="cw-flex cw-relative cw-bg-neutral-inset cw-w-full cw-py-3 cw-px-6 cw-gap-3 cw-rounded-lg cw-items-center cw-mt-2">
          <div className="cw-text-2xl">{allEmptyOptions[randomInteger].emoji}</div>
          <div className="cw-text-12 cw-text-muted">{allEmptyOptions[randomInteger].text}</div>
        </div>
      </div>
    );
  }

  const handleJoin = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    track(TrackingEvents.CHAT.NUX.SIDEBAR_NEXTMEETING_JOIN_CLICKED);
    isEmpty(eventToShow?.videoLink?.url)
      ? toast.error("Unable to open video link")
      : openVideoLink(eventToShow?.videoLink?.url);
  };

  const handleOpenEventCard = () => {
    if (!userProfile.primaryCalendar) return;
    setActiveEvent({
      externalEventId: eventToShow.externalEventId,
      calendarId: userProfile.primaryCalendar,
      type: eventToShow.type,
    });
  };

  const submitRescheduleMessage = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    const zonedStartISO = eventToShow.time.start.setZone(getRenderTimeZone()).toISO();
    const cql = generateRescheduleCQL(eventToShow.externalEventId);
    track(TrackingEvents.CHAT.NUX.SIDEBAR_NEXTMEETING_RESCHEDULE_CLICKED);
    void processMessage(
      `Reschedule ${eventToShow.title}`,
      {
        eventMentions: [
          {
            externalEventId: eventToShow.externalEventId,
            startTime: zonedStartISO,
            title: eventToShow.title,
          },
        ],
        personMentions: [],
      },
      cql,
    );
  };

  const hideRescheduleButton =
    eventToShow.isHold || eventToShow.isExternalEvent || eventToShow.isTravelTimeHold;

  const eventTime = `${eventToShow.time.toFormat("h:mm a").toLowerCase()} (${formatIntervalDuration(
    eventToShow.time,
  )})`;

  const getButtonList = () => {
    const buttons: ButtonProps[] = [];

    if (eventToShow.videoLink?.url) {
      buttons.push({
        text: "Join",
        onClick: handleJoin,
      });
    }
    if (!hideRescheduleButton) {
      buttons.push({
        text: eventToShow.canReschedule ? "Reschedule" : "Propose new time",
        onClick: submitRescheduleMessage,
      });
    }
    return buttons;
  };
  return (
    <div>
      <div className="cw-body-base cw-text-12 cw-text-subtle cw-font-semibold cw-my-2">
        {getMeetingStartText(eventToShow.time.start)}
      </div>
      <CalloutCard
        onClick={handleOpenEventCard}
        color={eventToShow.color}
        title={eventToShow.title}
        subText={eventTime}
        buttons={getButtonList()}
      />
    </div>
  );
};
