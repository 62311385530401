import { Divider } from "@clockwise/design-system";
import { Loader } from "@clockwise/design-system/src/components/Loader";
import { bg_emphasis, fg_muted } from "@clockwise/design-system/tokens";
import {
  ArrowForward,
  CheckCircleFilled,
  ClockwiseIntelligenceFilled,
  EmergencyHomeFilled,
  ExpandLess,
  ExpandMore,
  SentimentNeutralFilled,
} from "@clockwise/icons";
import { getRenderTimeZone } from "@clockwise/web-commons/src/util/time-zone.util";
import classNames from "classnames";
import { Interval } from "luxon";
import { X } from "phosphor-react";
import pluralize from "pluralize";
import React, { useState } from "react";
import { TradeoffBlock } from "../chat-plus-calendar/PersistedProposalContext";
import { AIMarkdown } from "../chat/ai-chat/components/ai-markdown";
import { ECAttendeeStack } from "../event-card/atoms/ECAttendeeStack";
import { formattedDateTime } from "../web-app-calendar/calendar-popover/utils/formattedDateTime";

const MAX_TRADEOFFS = 4;

export const TradeoffHeader = ({
  text,
  subtext,
  loading,
  Icon,
}: {
  text: string;
  subtext?: string;
  loading?: boolean;
  Icon?: React.ReactNode;
}) => {
  return (
    <>
      {loading ? (
        <Loader size={16} sentiment="busy" className="cw-h-4 cw-w-4 cw-text-busy cw-mr-1" />
      ) : (
        Icon || ""
      )}
      {loading ? (
        <div className="cw-caption cw-font-medium">Evaluating conflicts</div>
      ) : (
        <div className="cw-body-sm cw-text-12">
          <span className="cw-font-semibold">{text}</span> {subtext && <span>{subtext}</span>}
        </div>
      )}
    </>
  );
};

const TradeoffContainer = ({
  children,
  showBottomBorder,
}: {
  children: React.ReactNode;
  showBottomBorder: boolean;
}) => {
  return (
    <>
      <div className={classNames("cw-flex-col cw-items-center cw-w-full")}>{children}</div>
      {showBottomBorder && <Divider spacing="md" />}
    </>
  );
};

const TradeoffHeaderRow = ({
  children,
  isCollapsed,
  onClick,
  hideToggle = false,
}: {
  children: React.ReactNode;
  isCollapsed: boolean;
  onClick: () => void;
  hideToggle?: boolean;
}) => {
  return (
    <div className="cw-flex cw-items-center cw-cursor-pointer" onClick={onClick}>
      <>{children}</>
      {!hideToggle && (
        <div className="cw-ml-auto">
          {isCollapsed ? (
            <ExpandMore className="cw-h-4 cw-w-4 cw-stroke-2" fill={fg_muted} />
          ) : (
            <ExpandLess className="cw-h-4 cw-w-4 cw-stroke-2" fill={fg_muted} />
          )}
        </div>
      )}
    </div>
  );
};

const MoreTradeoffsHidden = ({ additional }: { additional: number }) => {
  return (
    <div className="cw-body-sm cw-text-12 cw-font-medium cw-items-center">
      {`+ ${additional} more`}
    </div>
  );
};

type TradeoffProps = {
  tradeoffBlock: TradeoffBlock;
  showBottomBorder: boolean;
  showAttendeeCount: boolean;
  onClickX?: (diffId: string) => void;
  loading: boolean;
};

export const AvailabilityIssue = ({
  tradeoffBlock,
  showBottomBorder,
  showAttendeeCount,
  loading,
}: TradeoffProps) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const tradeoffs = tradeoffBlock.tradeoffs;
  const attendeeCount = tradeoffBlock.affectedAttendees.length;
  let maxLen = MAX_TRADEOFFS;

  // If one over max, show so that we never show "+1 more"
  if (tradeoffs.length === MAX_TRADEOFFS + 1) {
    maxLen = MAX_TRADEOFFS + 1;
  }

  const moreThanMaxTradeoffs = tradeoffs.length > maxLen;
  const subtext = showAttendeeCount
    ? `⸱ ${attendeeCount} ${pluralize("attendee", attendeeCount)}`
    : undefined;

  return (
    <TradeoffContainer showBottomBorder={showBottomBorder}>
      <TradeoffHeaderRow isCollapsed={isCollapsed} onClick={() => setIsCollapsed(!isCollapsed)}>
        <TradeoffHeader
          text={"Conflicts"}
          subtext={subtext}
          loading={loading}
          Icon={<EmergencyHomeFilled className="cw-text-warning-muted cw-mr-1 cw-w-4 cw-h-4" />}
        />
      </TradeoffHeaderRow>
      {!isCollapsed && (
        <div className="cw-mt-1">
          <SchedulingTradeoffsList tradeoffBlock={tradeoffBlock} maxLength={maxLen} />
          {moreThanMaxTradeoffs && (
            <MoreTradeoffsHidden additional={tradeoffs.length - MAX_TRADEOFFS} />
          )}
        </div>
      )}
    </TradeoffContainer>
  );
};

export const FixableConflict = ({
  tradeoffBlock,
  showBottomBorder,
  showAttendeeCount,
  onClickX,
  loading,
}: TradeoffProps) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const tradeoffs = tradeoffBlock.tradeoffs;
  const attendeeCount = tradeoffBlock.affectedAttendees.length;
  let maxLen = MAX_TRADEOFFS;

  // If one over max, show so that we never show "+1 more"
  if (tradeoffs.length === MAX_TRADEOFFS + 1) {
    maxLen = MAX_TRADEOFFS + 1;
  }

  const moreThanMaxTradeoffs = tradeoffs.length > maxLen;
  const subtext = showAttendeeCount
    ? `⸱ ${attendeeCount} ${pluralize("attendee", attendeeCount)}`
    : undefined;

  return (
    <TradeoffContainer showBottomBorder={showBottomBorder}>
      <TradeoffHeaderRow isCollapsed={isCollapsed} onClick={() => setIsCollapsed(!isCollapsed)}>
        <TradeoffHeader
          text={"Fixable Conflicts"}
          subtext={subtext}
          loading={loading}
          Icon={<ClockwiseIntelligenceFilled className="cw-text-busy cw-mr-1 cw-w-4 cw-h-4" />}
        />
      </TradeoffHeaderRow>
      {!isCollapsed && (
        <div className="cw-mt-1">
          <SchedulingTradeoffsList
            tradeoffBlock={tradeoffBlock}
            maxLength={maxLen}
            isFixableConflict
            onClickX={onClickX}
          />
          <div>
            {moreThanMaxTradeoffs && (
              <MoreTradeoffsHidden additional={tradeoffs.length - MAX_TRADEOFFS} />
            )}
          </div>
        </div>
      )}
    </TradeoffContainer>
  );
};

export const Inconveniences = ({
  tradeoffBlock,
  showAttendeeCount,
  showBottomBorder,
}: TradeoffProps) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const schedulingTradeoffs = tradeoffBlock.tradeoffs;
  const attendeeCount = tradeoffBlock.affectedAttendees.length;
  let maxLen = MAX_TRADEOFFS;

  // If one over max, show so that we never show "+1 more"
  if (schedulingTradeoffs.length === MAX_TRADEOFFS + 1) {
    maxLen = MAX_TRADEOFFS + 1;
  }

  const moreThanMaxTradeoffs = schedulingTradeoffs.length > maxLen;
  const subtext = showAttendeeCount
    ? `⸱ ${attendeeCount} ${pluralize("attendee", attendeeCount)}`
    : undefined;

  return (
    <TradeoffContainer showBottomBorder={showBottomBorder}>
      <TradeoffHeaderRow isCollapsed={isCollapsed} onClick={() => setIsCollapsed(!isCollapsed)}>
        <TradeoffHeader
          text={"Inconveniences"}
          subtext={subtext}
          Icon={<SentimentNeutralFilled className="cw-text-subtle cw-mr-1 cw-w-4 cw-h-4" />}
        />
      </TradeoffHeaderRow>
      {!isCollapsed && (
        <div className="cw-mt-1">
          <SchedulingTradeoffsList tradeoffBlock={tradeoffBlock} maxLength={maxLen} />
          <div>
            {moreThanMaxTradeoffs && (
              <MoreTradeoffsHidden additional={schedulingTradeoffs.length - MAX_TRADEOFFS} />
            )}
          </div>
        </div>
      )}
    </TradeoffContainer>
  );
};

export const WorksForEveryone = () => {
  return (
    <div className="cw-flex cw-items-center">
      <CheckCircleFilled className="cw-text-positive cw-mr-1 cw-w-4 cw-h-4" />
      <TradeoffHeader text={"No conflicts"} />
    </div>
  );
};

export const NoIssues = ({
  attendeeCount,
  showAttendeeCount,
}: {
  attendeeCount: number;
  showAttendeeCount: boolean;
}) => {
  const text = showAttendeeCount
    ? `No issues ⸱ ${attendeeCount} ${pluralize("attendee", attendeeCount)}`
    : "No issues";
  return (
    <div className="cw-flex cw-items-center cw-opacity-50">
      <CheckCircleFilled className="cw-text-positive cw-mr-1 cw-w-4 cw-h-4" />
      <TradeoffHeader text={text} />
    </div>
  );
};

const SchedulingTradeoffsList = ({
  tradeoffBlock,
  maxLength,
  isFixableConflict,
  onClickX,
}: {
  tradeoffBlock: TradeoffBlock;
  maxLength: number;
  isFixableConflict?: boolean;
  onClickX?: (diffId: string) => void;
}) => {
  return (
    <div className="cw-flex-col">
      {tradeoffBlock.tradeoffs.slice(0, maxLength).map((tradeoff, index) => {
        const { diffId, updatedTime } = tradeoff;
        const zone = getRenderTimeZone();
        const updatedStartTime =
          updatedTime?.__typename === "DateTimeRange"
            ? Interval.fromISO(updatedTime.dateTimeRange).start
            : updatedTime?.__typename === "DateRange"
            ? Interval.fromISO(updatedTime.dateRange).start
            : null;

        const isRemovable = onClickX && diffId;
        const tradeoffHasBeenRemoved = !diffId && isFixableConflict;
        const showUpdatedStartTime = updatedStartTime && !tradeoffHasBeenRemoved;
        const hasEndContent = showUpdatedStartTime || tradeoffHasBeenRemoved;
        return (
          <div key={index} className="cw-flex cw-items-center cw-py-1">
            <div className="cw-mr-1.5">
              <ECAttendeeStack
                attendees={tradeoff.attendees}
                maxShown={3}
                borderColorOverride={bg_emphasis}
              />
            </div>
            <div className="cw-flex cw-body-sm cw-text-11 cw-font-medium cw-items-center">
              <AIMarkdown text={tradeoff.title.replace("Conflict: ", "")} />
            </div>
            {hasEndContent && (
              <div className="cw-flex cw-flex-row cw-items-center cw-gap-1 cw-shrink-0 cw-text-muted cw-body-sm cw-text-xs">
                <ArrowForward fontSize="inherit" color="inherit" className="cw-mb-[1px]" />
                {showUpdatedStartTime && formattedDateTime(updatedStartTime, zone)}
                {tradeoffHasBeenRemoved && <>Won't Fix</>}
              </div>
            )}
            {isRemovable && (
              <div
                className="cw-flex cw-flex-row cw-grow cw-items-center cw-opacity-0 group-hover:cw-opacity-100"
                role="button"
                aria-label="Don't fix"
              >
                <X
                  className="cw-text-positive-muted cw-h-3 cw-w-3 cw-stroke-2 cw-cursor-pointer cw-flex cw-grow-0 cw-ml-1"
                  size={12}
                  onClick={() => {
                    onClickX?.(diffId);
                  }}
                />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
