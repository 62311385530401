import { Button, Skeleton, TextField } from "@clockwise/design-system";
import {
  useGatewayMutation,
  useGatewayQuery,
} from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { TeamAvatar } from "@clockwise/web-commons/src/ui/team-avatar/TeamAvatar";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { TeamNameDocument, UpdateTeamNameDocument } from "./__generated__/TeamName.v2.generated";

type Props = {
  id?: string;
};

export function TeamName({ id }: Props) {
  const [editing, setEditing] = useState(false);
  const { data, loading } = useGatewayQuery(TeamNameDocument, {
    variables: { id: id! },
    skip: !id,
  });
  const [updateName, { loading: saving }] = useGatewayMutation(UpdateTeamNameDocument);
  const name = data?.team?.name ?? "Unknown Team";
  const [currentName, setCurrentName] = useState(name);

  const onSave = async () => {
    await updateName({
      variables: {
        id: id!,
        name: currentName,
      },
      onCompleted: () => {
        setEditing(false);
        toast.success("Team name updated!");
      },
      onError: (error) => {
        let errorMessage = "Failed to update team name";
        if (error.message.match(/Team name is already in use/)) {
          errorMessage = "Team name is already in use";
        } else if (error.message.match(/Team name is too long/)) {
          errorMessage = "Team name is too long";
        }
        toast.error(errorMessage);
      },
    });
  };

  if (!id) {
    return null;
  }

  if (loading) {
    return (
      <div className="cw-flex cw-gap-2 cw-heading-3xl cw-leading-none cw-items-center">
        <div className="xl:cw-block cw-hidden">
          <Skeleton className="cw-rounded-full" width={40} height={40} />
        </div>
        <Skeleton className="cw-rounded-md" width={200} height={40} />
      </div>
    );
  }

  return (
    <div className="cw-flex cw-gap-2 cw-heading-3xl cw-leading-none cw-items-center">
      <div className="xl:cw-block cw-hidden">
        <TeamAvatar size="large" id={id} teamName={name} />
      </div>
      {editing ? (
        <TextField
          fieldSize="large"
          value={currentName}
          onChange={(e) => setCurrentName(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              void onSave();
            }
          }}
        />
      ) : (
        <div className="cw-heading-2xl">{name}</div>
      )}
      {editing ? (
        <div className="cw-flex cw-flex-col cw-gap-1">
          <Button
            type="submit"
            size="mini"
            sentiment="positive"
            onClick={onSave}
            disabled={saving || currentName === name}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            size="mini"
            onClick={() => {
              setEditing(false);
              setCurrentName(name);
            }}
            disabled={saving}
          >
            Cancel
          </Button>
        </div>
      ) : (
        <Button
          variant="outlined"
          size="mini"
          aria-label="Edit team name"
          onClick={() => {
            setCurrentName(name);
            setEditing(true);
          }}
        >
          Edit
        </Button>
      )}
    </div>
  );
}
