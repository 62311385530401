import { Skeleton } from "@clockwise/design-system";
import { AttendeeAvatar } from "@clockwise/web-commons/src/ui/AttendeeAvatar";
import { AttendeeAvatarStack } from "@clockwise/web-commons/src/ui/AttendeeAvatarStack";
import { LegacyTooltip } from "@clockwise/web-commons/src/ui/tooltip";
import { InfoRounded } from "@material-ui/icons";
import classNames from "classnames";
import { isEmpty } from "lodash";
import React, { memo } from "react";
import { ProposalThreadAttendees } from "./useProposalThreadEventDetails";

export const TooltipHeader = memo(function Header({
  title,
  description,
  attendees,
  loading,
}: {
  title: string;
  attendees: ProposalThreadAttendees;
  loading: boolean;
  description?: string;
}) {
  const showAttendees = !loading && !isEmpty(attendees);
  return (
    <div
      className={classNames(
        "cw-text-muted cw-w-full cw-px-3 cw-py-2 cw-cursor-pointer cw-flex-col",
        "cw-bg-neutral cw-border-t-0 cw-border-x-0 cw-border-b cw-border-solid cw-border-muted",
        "cw-overflow-hidden cw-whitespace-nowrap cw-text-ellipsis",
      )}
    >
      <div className="cw-flex cw-items-center cw-justify-between cw-gap-[3px] cw-overflow-hidden cw-whitespace-nowrap cw-text-ellipsis cw-w-full">
        <span className="cw-overflow-hidden cw-whitespace-nowrap cw-text-ellipsis cw-body-sm cw-text-12 cw-font-medium cw-text-subtle">
          {title}
        </span>
        <LegacyTooltip header={title} placement="top" body={description || ""} showArrow={false}>
          <InfoRounded className="cw-text-subtle cw-w-[14px] cw-h-[14px] cw-mt-1" />
        </LegacyTooltip>
      </div>

      {loading && (
        <div className="cw-flex cw-gap-x-2 cw-w-full cw-mt-1">
          <Skeleton className="cw-rounded-full" width={24} height={24} />
          <Skeleton className="cw-rounded-full" width={24} height={24} />
        </div>
      )}
      {showAttendees && <HeaderAvatars attendees={attendees} />}
    </div>
  );
});

const MAX_ATTENDEES_SHOWN = 5;

const getStringListOfAttendees = (attendees: ProposalThreadAttendees) => {
  return attendees.map((attendee) => attendee.person.displayName).join(", ");
};

const HeaderAvatars = ({ attendees }: { attendees: ProposalThreadAttendees }) => {
  // If there are 6 show attendees, if 2+ over 5, show "+number" avatar
  const numberAttendeesToShow =
    attendees.length === MAX_ATTENDEES_SHOWN + 1 ? MAX_ATTENDEES_SHOWN + 1 : MAX_ATTENDEES_SHOWN;

  return (
    <div className="cw-flex cw-gap-x-1 cw-w-full cw-mt-1">
      <AttendeeAvatarStack
        maxShown={numberAttendeesToShow}
        size="large"
        overlap={false}
        disableAnimation
        overflowToolTipContent={getStringListOfAttendees(attendees.slice(numberAttendeesToShow))}
      >
        {attendees.map((attendee) => {
          const profileNoName = {
            externalImageUrl: attendee.person.externalImageUrl,
          };
          return (
            <AttendeeAvatar
              key={attendee.person.id}
              isOrganizer={attendee.isOrganizer ?? false}
              profile={profileNoName}
              calendarId={attendee.person.email}
              iconType={attendee.responseStatus}
              size="large"
              displayName={attendee.person.displayName}
            />
          );
        })}
      </AttendeeAvatarStack>
    </div>
  );
};
