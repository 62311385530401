import { useUpdateCurrentProposal } from "#webapp/src/components/chat-plus-calendar/CurrentProposalContext";
import { useUserProfile } from "#webapp/src/components/hooks/useUserProfile";
import { getDefaultEventName } from "#webapp/src/components/web-app-calendar/calendar-week/utils/defaultEventName";
import { CalendarProfile } from "#webapp/src/components/web-app-calendar/multi-calendar-select/util/MultiCalendarSelect.util";
import { useUserConfrencingTypes } from "#webapp/src/hooks/useUserConfrencingTypes";
import { useFeatureFlag } from "#webapp/src/launch-darkly/useLaunchDarkly";
import { Button, Skeleton, Tooltip } from "@clockwise/design-system";
import {
  Add,
  BedtimeFilled,
  BlockFilled,
  Check,
  CheckCircleFilled,
  Close,
  LightbulbFilled,
  NewsFilled,
  RestaurantFilled,
} from "@clockwise/icons";
import { AvailabilityStatus, FlexRange } from "@clockwise/schema/v2";
import { useCurrentTime } from "@clockwise/web-commons/src/hooks/useCurrentTime";
import { AttendeeAvatar } from "@clockwise/web-commons/src/ui/AttendeeAvatar";
import {
  EventType,
  useUpdateActiveEvent,
} from "@clockwise/web-commons/src/util/ActiveEventContext";
import { useReadCalendar } from "@clockwise/web-commons/src/util/CalendarContext";
import { generateScheduleCQL } from "@clockwise/web-commons/src/util/generateCQL";
import { getRenderTimeZone } from "@clockwise/web-commons/src/util/time-zone.util";
import classNames from "classnames";
import { compact, differenceWith, intersection, range, uniq } from "lodash";
import { DateTime, IANAZone } from "luxon";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useBoolean } from "usehooks-ts";
import {
  addCalendarIds,
  removeCalendarIds,
} from "../../../../state/actions/multi-calendar.actions";
import { IReduxState } from "../../../../state/reducers/root.reducer";
import { TrackingEvents, track } from "../../../../util/analytics.util";
import { useReadCalendarColors } from "../../../web-app-calendar/hooks/CalendarColorsContext";
import { Collaborator, useCollaborators } from "../hooks/useCollaborators";
import { useProcessMessage } from "../hooks/useProcessMessage";
import { CollaboratorSearch } from "./CollaboratorSearch";

const statusToIconWithDescription = (status: AvailabilityStatus | null) => {
  switch (status) {
    case AvailabilityStatus.FocusTime:
      return {
        icon: LightbulbFilled,
        description: "Focus Time",
      };
    case AvailabilityStatus.Meeting:
    case AvailabilityStatus.Other:
      return {
        icon: NewsFilled,
        description: "Busy",
      };
    case AvailabilityStatus.OutOfOffice:
      return {
        icon: BlockFilled,
        description: "Out of Office",
      };
    case AvailabilityStatus.WorkingHours:
      return {
        icon: CheckCircleFilled,
        description: "Available",
      };
    case AvailabilityStatus.Lunch:
      return {
        icon: RestaurantFilled,
        description: "Lunch",
      };
    case AvailabilityStatus.BeforeWorkingHours:
    case AvailabilityStatus.AfterHours:
    case AvailabilityStatus.Weekend:
    case AvailabilityStatus.DayOff:
    case AvailabilityStatus.Holiday:
      return {
        icon: BedtimeFilled,
        description: "Outside working hours",
      };
    default:
      return null;
  }
};

const ScheduleTimeAction = ({
  duration,
  onClick,
  nameOrEmail,
}: {
  duration: number;
  onClick: (e: React.MouseEvent) => void;
  nameOrEmail: string;
}) => {
  return (
    <Tooltip title={`Schedule ${duration} minutes with ${nameOrEmail}`}>
      <Button onClick={onClick} size="mini" variant="outlined">{`${duration}m`}</Button>
    </Tooltip>
  );
};

const PushPinOn = () => <Add className="cw-w-4 cw-h-4 cw-text-positive-muted" />;
const PushPinOff = () => <Close className="cw-w-4 cw-h-4 cw-text-muted" />;

const PinToggle = ({
  onClick,
  isPinned,
  fullName,
}: {
  onClick: (e: React.MouseEvent) => void;
  isPinned: boolean;
  fullName: string | null;
}) => {
  return (
    <Tooltip
      title={
        isPinned
          ? `Unpin ${fullName ? fullName : ""} from this list`
          : `Pin ${fullName ? fullName : ""} to this list`
      }
    >
      <Button
        onClick={onClick}
        size="mini"
        variant="outlined"
        startIcon={isPinned ? PushPinOff : PushPinOn}
      />
    </Tooltip>
  );
};

const ONE_MINUTE = 60 * 1000;

const CollaboratorCard = ({
  collaborator,
  isInMultiCalList,
  removeCallback,
  onPinToggle,
  onTriggerProposal,
}: {
  collaborator: Collaborator;
  isInMultiCalList: boolean;
  removeCallback: (calId: string) => void;
  onPinToggle: (collaborator: Collaborator) => void;
  onTriggerProposal?: ({
    initialAttendeeIds,
    durationMinutes,
  }: {
    initialAttendeeIds?: string[];
    durationMinutes?: number;
  }) => void;
}) => {
  const currentTime = useCurrentTime(ONE_MINUTE);

  const dispatch = useDispatch();
  const calendarColors = useReadCalendarColors();
  const { processMessage, canProcessMessage } = useProcessMessage();

  const calendarColor = calendarColors[collaborator.calendarId || ""]?.border;

  const { value: isFocused, setTrue: setFocused, setFalse: setNotFocused } = useBoolean(false);
  const containerRef = React.useRef<HTMLDivElement>(null);

  const toggleUser = () => {
    if (!collaborator?.calendarId) return;
    track(TrackingEvents.CHAT.NUX.SIDEBAR_COLLABORATOR_VIEW_CALENDAR_CLICKED, {
      isAdding: !isInMultiCalList,
    });
    if (isInMultiCalList) {
      if (!collaborator.isPinned) {
        removeCallback(collaborator.calendarId);
      }
      dispatch(removeCalendarIds([collaborator.calendarId]));
    } else {
      dispatch(addCalendarIds([collaborator.calendarId]));
    }
  };

  const generateCQLForCollaborator = (duration: number) => {
    if (!collaborator?.calendarId) return;
    const name = collaborator.fullName || collaborator.calendarId;
    const cql = generateScheduleCQL([collaborator.calendarId], duration);
    track(TrackingEvents.CHAT.NUX.SIDEBAR_COLLABORATOR_QUICK_CREATE_CLICKED, { duration });
    void processMessage(
      `Schedule ${duration} minutes with ${name}`,
      {
        eventMentions: [],
        personMentions: [
          {
            calendarId: collaborator.calendarId,
            name: name,
          },
        ],
      },
      cql,
    );
  };

  // prevent ui flicker on tabbing
  const delayedSetNotfocused = useCallback(() => {
    setTimeout(() => {
      const focusWithin = containerRef.current?.contains(document.activeElement);
      if (!focusWithin) {
        setNotFocused();
      }
    }, 10);
  }, [setNotFocused]);

  const initialAttendeeIds = [collaborator.calendarId];

  let actions: React.JSX.Element[] = [];
  if (isFocused) {
    if (canProcessMessage) {
      actions = [
        <ScheduleTimeAction
          key={"time-action-15"}
          duration={15}
          nameOrEmail={collaborator.fullName || collaborator.calendarId}
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation();
            if (onTriggerProposal) {
              onTriggerProposal({ initialAttendeeIds, durationMinutes: 15 });
            } else {
              generateCQLForCollaborator(15);
            }
          }}
        />,
        <ScheduleTimeAction
          key={"time-action-30"}
          duration={30}
          nameOrEmail={collaborator.fullName || collaborator.calendarId}
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation();
            if (onTriggerProposal) {
              onTriggerProposal({
                initialAttendeeIds,
                durationMinutes: 30,
              });
            } else {
              generateCQLForCollaborator(30);
            }
          }}
        />,
        <ScheduleTimeAction
          key={"time-action-60"}
          duration={60}
          nameOrEmail={collaborator.fullName || collaborator.calendarId}
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation();
            if (onTriggerProposal) {
              onTriggerProposal({ initialAttendeeIds, durationMinutes: 60 });
            } else {
              generateCQLForCollaborator(60);
            }
          }}
        />,
        <div key={"togglecal-1"} className="cw-flex cw-items-center">
          <div className="cw-w-px cw-h-2 cw-mr-1 cw-ml-0.5 cw-bg-[#d6d6d6]" />
          <PinToggle
            isPinned={collaborator.isPinned}
            fullName={collaborator.fullName}
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation();
              onPinToggle(collaborator);
            }}
          />
        </div>,
      ];
    }
  }

  const statusIconWithDescription = statusToIconWithDescription(collaborator.availabilityStatus);
  const StatusIcon = statusIconWithDescription?.icon;

  const timeZone = collaborator.timeZone && IANAZone.create(collaborator.timeZone);
  const time = timeZone
    ? currentTime.setZone(timeZone).toFormat("h:mma").toLocaleLowerCase() +
      " " +
      currentTime.setZone(timeZone).toFormat("ZZZZ")
    : "";

  return (
    <div
      ref={containerRef}
      tabIndex={0}
      onMouseEnter={setFocused}
      onMouseLeave={setNotFocused}
      onBlur={delayedSetNotfocused}
      onFocus={setFocused}
      onClick={toggleUser}
      className="cw-flex cw-group cw-justify-between cw-items-center cw-rounded-lg cw-p-1 cw-cursor-pointer hover:cw-bg-neutral-inset"
    >
      <div className="cw-flex cw-gap-3 cw-items-center cw-truncate">
        {isFocused || isInMultiCalList ? (
          <div className="cw-w-6 cw-h-6 cw-min-w-[24px] cw-flex cw-items-center cw-justify-center">
            <div
              style={{
                backgroundColor: isInMultiCalList ? calendarColor : "",
                borderColor: isInMultiCalList ? calendarColor ?? "#d6d6d6" : "#d6d6d6",
              }}
              className={classNames(
                "cw-w-4 cw-h-4 cw-min-w-[16px] cw-border-[1.5px] cw-flex cw-items-center cw-justify-center cw-border-solid cw-rounded-[4px]",
              )}
            >
              {isInMultiCalList && <Check className="cw-text-onEmphasis cw-w-3" />}
            </div>
          </div>
        ) : (
          <AttendeeAvatar
            hideTooltip
            borderColor="#d6d6d6"
            calendarId={collaborator.calendarId || undefined}
            displayName={collaborator.fullName || undefined}
            profile={{
              externalImageUrl: collaborator.externalImageUrl,
            }}
            size="large"
          />
        )}
        <div className={classNames("cw-body-sm cw-font-medium", { "cw-max-w-[117px]": isFocused })}>
          {collaborator.fullName || collaborator.calendarId}
          {statusIconWithDescription && collaborator.isPinned && (
            <div className="cw-flex cw-items-center cw-gap-1">
              {StatusIcon && <StatusIcon className="cw-w-3 cw-h-3 cw-text-default-disabled" />}
              <div className="cw-text-subtle cw-font-medium cw-text-12 cw-truncate">
                {statusIconWithDescription.description} · {time}
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="group-hover:cw-visible"></div>
      {actions.length ? (
        <div className="cw-flex cw-gap-0.5">{actions.map((action) => action)}</div>
      ) : null}
    </div>
  );
};

export const CustomCollaborators = ({
  topCollaborators,
  customCollaborators,
  loading,
}: {
  topCollaborators: Collaborator[];
  customCollaborators: Collaborator[];
  loading: boolean;
}) => {
  const [timeSuggestionsTabEnabled] = useFeatureFlag("TimeSuggestionsTab");
  const [tempPersons, setTempPersons] = React.useState<Collaborator[]>([]);
  const { updateCollaborators } = useCollaborators();
  const { userProfile } = useUserProfile();
  const { processMessage, canProcessMessage } = useProcessMessage();
  const dispatch = useDispatch();
  const { focusedDate } = useReadCalendar();
  const { initOrRescheduleProposal } = useUpdateCurrentProposal();
  const updateActiveEvent = useUpdateActiveEvent();
  const { preferredConferencingType } = useUserConfrencingTypes();

  const selectedCalendarIds = useSelector(
    (state: IReduxState) => state.multiCalendar.calendarIdsToFullyShow,
  );

  const pinnedCollaborators = [...tempPersons, ...customCollaborators];
  const remainingTopCollaborators = topCollaborators.filter(
    (top) => !pinnedCollaborators.some((custom) => custom.calendarId === top.calendarId),
  );

  useEffect(() => {
    // If thereare no custom collaborators, default to the top collaborators and pin them
    if (!customCollaborators.length && topCollaborators.length && !loading) {
      updateCollaborators(remainingTopCollaborators.slice(0, 6));
    }
  }, [remainingTopCollaborators.length, topCollaborators.length, loading]);

  useEffect(() => {
    // If the redux selected Ids change in count, that means that the user has removed a calendar from multi-cal.
    // This logic will remove it from our local state as well
    const tempPeronsIds = tempPersons.map((p) => p.calendarId);
    const differenceInSelectedIds = differenceWith(tempPeronsIds, selectedCalendarIds);
    if (differenceInSelectedIds.length) {
      const newTempPersons = tempPersons.filter(
        (p) => !differenceInSelectedIds.includes(p.calendarId),
      );
      setTempPersons(newTempPersons);
    }
  }, [selectedCalendarIds.length]);

  const allPersons = [...pinnedCollaborators, ...remainingTopCollaborators];

  if (!allPersons.length && !loading) return null;

  const collaboratorsAndPinnedPersonsAndYou = compact([
    userProfile.primaryCalendar,
    ...pinnedCollaborators.map((p) => p.calendarId),
  ]);

  const removeTempProfile = (calId: string) => {
    const tempCopy = [...tempPersons];
    const newTempPersons = tempCopy.filter((p) => p.calendarId !== calId);
    setTempPersons(newTempPersons);
  };

  const toggleCollabPin = (collaborator: Collaborator) => {
    const isCurrentlyPinned = customCollaborators.some(
      (c) => c.calendarId === collaborator.calendarId,
    );
    if (isCurrentlyPinned) {
      const newCustomCollaborators = customCollaborators.filter(
        (p) => p.calendarId !== collaborator.calendarId,
      );
      updateCollaborators(newCustomCollaborators);
      dispatch(removeCalendarIds([collaborator.calendarId]));
    } else {
      removeTempProfile(collaborator.calendarId);
      updateCollaborators([collaborator, ...customCollaborators]);
    }
  };

  const addTempProfile = (person: CalendarProfile) => {
    if (!person?.primaryCalendar) return;
    dispatch(addCalendarIds([person.primaryCalendar]));
    const isAlreadyInList = !!collaboratorsAndPinnedPersonsAndYou.filter((calId) => {
      return calId === person.primaryCalendar;
    }).length;
    if (!isAlreadyInList) {
      setTempPersons([
        {
          calendarId: person.primaryCalendar,
          sharedMinutes: null,
          isPinned: false,
          fullName: `${person.profile?.givenName} ${person.profile?.familyName}`,
          externalImageUrl: person.profile?.externalImageUrl || null,
          availabilityStatus: null,
          timeZone: null,
        },
        ...tempPersons,
      ]);
    }
  };

  const showMultiSchedule = intersection(
    selectedCalendarIds,
    allPersons.map((p) => p.calendarId),
  );

  const generateCQLForMultipleCollaborators = () => {
    const collaborators = pinnedCollaborators.filter((pC) =>
      showMultiSchedule.includes(pC.calendarId),
    );
    const listOfNames = collaborators.map((c) => c.fullName || c.calendarId).join(", ");
    const listOfCalIds = collaborators.map((c) => c.calendarId);
    const listOfMentions = collaborators.map((collaborator) => {
      const name = collaborator.fullName || collaborator.calendarId;
      return {
        calendarId: collaborator.calendarId,
        name: name,
      };
    });
    const cql = generateScheduleCQL(listOfCalIds, 30);
    track(TrackingEvents.CHAT.NUX.SIDEBAR_MULTI_COLLABORATOR_QUICK_CREATE_CLICKED, {
      duration: 30,
    });
    void processMessage(
      `Schedule 30 minutes with ${listOfNames}`,
      {
        eventMentions: [],
        personMentions: listOfMentions,
      },
      cql,
    );
  };

  const handleScheduleClick = () => {
    const durationMinutes = 60;
    const initialAttendeeIds = pinnedCollaborators
      .filter((pC) => showMultiSchedule.includes(pC.calendarId))
      .map((pC) => pC.calendarId);

    triggerProposal({ initialAttendeeIds, durationMinutes });

    track(TrackingEvents.CHAT.NUX.SIDEBAR_MULTI_COLLABORATOR_QUICK_CREATE_CLICKED, {
      duration: durationMinutes,
    });
  };

  const handleScheduleTimeActionClick = ({
    initialAttendeeIds,
    durationMinutes,
  }: {
    initialAttendeeIds?: string[];
    durationMinutes?: number;
  }) => {
    // clear all selected calendar IDs
    dispatch(removeCalendarIds(selectedCalendarIds));
    triggerProposal({ initialAttendeeIds, durationMinutes });
  };

  const triggerProposal = ({
    initialAttendeeIds: _initialAttendeeIds = [],
    durationMinutes = 60,
  }: {
    initialAttendeeIds?: string[];
    durationMinutes?: number;
  }) => {
    const initialAttendeeIds = uniq(
      userProfile.primaryCalendar
        ? [userProfile.primaryCalendar, ..._initialAttendeeIds]
        : _initialAttendeeIds,
    );

    const timeZone = getRenderTimeZone();
    const startTime = DateTime.fromISO(focusedDate)
      .setZone(timeZone)
      .set({
        hour: DateTime.now().hour,
        minute: 0,
      })
      .plus({
        minutes: Math.ceil(DateTime.now().minute / 30) * 30,
      });
    const endTime = startTime.plus({ minutes: durationMinutes });
    const title = getDefaultEventName({
      attendees: initialAttendeeIds,
      myProfile: userProfile,
      collaborators: [],
    });
    const conferenceType = initialAttendeeIds.length > 1 ? preferredConferencingType : null;
    const eventCalendarId = userProfile.primaryCalendar || "";
    const flexDetails = {
      isFlexible: initialAttendeeIds.length > 1,
      flexRange: FlexRange.Day,
    };
    updateActiveEvent({
      type: EventType.Proposal,
      externalEventId: "",
      calendarId: eventCalendarId,
    });
    initOrRescheduleProposal({
      proposalId: null,
      title,
      eventCalendarId,
      startTime,
      endTime,
      timeZone,
      initialAttendeeIds,
      attendees: [],
      allDay: false,
      description: "",
      recurrenceRule: null,
      location: null,
      conferenceType,
      flexDetails,
      meta: {
        isDragging: false,
        isFindATime: true,
      },
      transparency: "opaque",
      visibility: "default",
    });
  };

  return (
    <div className="cw-rounded-lg cw-w-full cw-flex cw-flex-col">
      <div className="cw-flex cw-justify-between cw-items-center cw-h-6">
        <div className="cw-body-base cw-text-12 cw-text-subtle cw-font-semibold cw-mb-1">
          Pinned calendars
        </div>
        {!!showMultiSchedule.length &&
          (timeSuggestionsTabEnabled ? (
            <Button onClick={handleScheduleClick} size="mini" variant="text" sentiment="positive">
              {`Schedule (${showMultiSchedule.length})`}
            </Button>
          ) : canProcessMessage ? (
            <Button
              onClick={generateCQLForMultipleCollaborators}
              size="mini"
              variant="text"
              sentiment="positive"
            >
              {`Schedule (${showMultiSchedule.length})`}
            </Button>
          ) : null)}
      </div>

      <CollaboratorSearch
        onSelect={addTempProfile}
        remainingTopCollaborators={remainingTopCollaborators.slice(0, 5)}
        hiddenCalIds={selectedCalendarIds}
      />
      <div className="cw-mt-1">
        {loading ? (
          range(6).map((_, index) => (
            <div
              key={`collab-loading-${index}`}
              className="cw-flex cw-gap-3 cw-items-center cw-my-1"
            >
              <Skeleton className="cw-rounded-full" height={24} width={24} />
              <Skeleton height={24} className="cw-flex-1 cw-rounded-lg" />
            </div>
          ))
        ) : (
          <>
            {tempPersons.map((tempCollaborator) => (
              <CollaboratorCard
                collaborator={tempCollaborator}
                key={tempCollaborator.calendarId}
                removeCallback={removeTempProfile}
                isInMultiCalList={selectedCalendarIds.includes(tempCollaborator.calendarId || "")}
                onPinToggle={toggleCollabPin}
                onTriggerProposal={
                  timeSuggestionsTabEnabled ? handleScheduleTimeActionClick : undefined
                }
              />
            ))}
            {tempPersons.length > 0 && (
              <div className="cw-border-solid cw-border-b cw-border-subtle cw-my-1" />
            )}
            {customCollaborators.map((pinnedCollaborator) => (
              <CollaboratorCard
                collaborator={pinnedCollaborator}
                key={pinnedCollaborator.calendarId}
                removeCallback={removeTempProfile}
                isInMultiCalList={selectedCalendarIds.includes(pinnedCollaborator.calendarId || "")}
                onPinToggle={toggleCollabPin}
                onTriggerProposal={
                  timeSuggestionsTabEnabled ? handleScheduleTimeActionClick : undefined
                }
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
};
