import { Skeleton } from "@clockwise/design-system";
import classNames from "classnames";
import React from "react";

// Simple wrapper that adds a custom skeleton state if loading is true
export const Async = ({
  loading,
  loadingHeight,
  loadingWidth,
  loadingVariant = "text",
  children,
  containerClasses,
}: {
  loading: boolean;
  loadingHeight: string | number;
  loadingWidth?: string | number;
  loadingVariant?: "text" | "rect" | "circle";
  children: React.ReactNode;
  containerClasses?: string;
}) => {
  if (loading) {
    return (
      <Skeleton
        className={classNames(containerClasses, {
          "cw-flex-1": loadingWidth === undefined,
          "cw-rounded-full": loadingVariant === "circle",
        })}
        width={loadingWidth}
        height={loadingHeight}
      />
    );
  } else {
    return <>{children}</>;
  }
};
