import { Button } from "@clockwise/design-system";
import { Close } from "@clockwise/design-system/icons";
import { EditEventAppliesTo, TradeoffType } from "@clockwise/schema/v2";
import { EventThread } from "@clockwise/web-commons/src/ui/event-thread";
import { getFormattedDateRange } from "@clockwise/web-commons/src/util/date.util";
import { Dialog } from "@material-ui/core";
import classNames from "classnames";
import { DateTime } from "luxon";
import pluralize from "pluralize";
import React from "react";
import { TradeoffBlock } from "../chat-plus-calendar/PersistedProposalContext";
import { SaveDropdownForRecurringEvent } from "../event-card/atoms/SaveDropdown";
import { TradeoffBlocks } from "../tradeoffs/TradeoffBlocks";
import { FindMoreTimesButton } from "../web-app-calendar/event-context-menu/atoms/QuickReschedule";

type TradeoffAttendee = {
  person: {
    givenName: string | null;
    familyName: string | null;
    externalImageUrl: string | null;
  };
};

type GQLTime =
  | {
      __typename: "DateRange";
      dateRange: string;
    }
  | {
      __typename: "DateTimeRange";
      dateTimeRange: string;
    }
  | null;

export type Tradeoff = {
  title: string;
  attendees: TradeoffAttendee[];
  event: {
    title: string;
  } | null;
  updatedTime?: GQLTime;
  diffId?: string | null;
};

type OnConfirmProposal = () => void;
type OnSaveNonRecurringEvent = (externalEventId: string, start: string) => void;
type OnSaveRecurringEvent = (appliesTo: EditEventAppliesTo) => void;

type Props = {
  externalEventId: string;
  proposedStart: string;
  existingStart: string;
  title: string;
  duration: number;
  newDuration?: number;
  isOpen: boolean;
  tradeoffBlocks?: TradeoffBlock[];
  disabled?: boolean;
  loading?: boolean;
  onClose: () => void;
  onSaveNonRecurringEvent: OnSaveNonRecurringEvent;
  onSaveRecurringEvent: OnSaveRecurringEvent;
  onConfirmProposal: OnConfirmProposal;
  submitRescheduleMessage?: () => void;
  isRecurring?: boolean;
  isOnDNDProposal?: boolean;
  isHold?: boolean;
};

export const DEFAULT_SAVE_BUTTON_TEXT = "Save";
const DEFAULT_SAVE_BUTTON_TEXT_FOR_HOLDS = "Save";
const getSaveButtonText = (_isHold: boolean) => {
  // Switch to this commented code once `send` works
  // return isHold ? DEFAULT_SAVE_BUTTON_TEXT_FOR_HOLDS : DEFAULT_SAVE_BUTTON_TEXT;

  return DEFAULT_SAVE_BUTTON_TEXT_FOR_HOLDS;
};

const SaveEventButton = ({
  disabled,
  onSaveNonRecurringEvent,
  externalEventId,
  proposedStart,
  text = DEFAULT_SAVE_BUTTON_TEXT,
}: {
  disabled: boolean;
  onSaveNonRecurringEvent: OnSaveNonRecurringEvent;
  externalEventId: string;
  proposedStart: string;
  text?: string;
}) => {
  return (
    <Button
      sentiment="positive"
      size="xsmall"
      onClick={() => onSaveNonRecurringEvent(externalEventId, proposedStart)}
      disabled={disabled}
    >
      {text}
    </Button>
  );
};
const SaveSBMProposalButton = ({
  disabled,
  onSave,
  text = "Save",
}: {
  disabled: boolean;
  onSave: () => void;
  text?: string;
}) => {
  return (
    <Button
      sentiment="neutral"
      variant="outlined"
      size="xsmall"
      onClick={() => onSave()}
      disabled={disabled}
    >
      {text}
    </Button>
  );
};

const FIX_WITH_SBM_TEXT = "Save & fix conflicts";
const getSaveAndFixConflictsText = (numberOfActiveTradeoffs: number) => {
  if (numberOfActiveTradeoffs === 0) {
    return FIX_WITH_SBM_TEXT;
  }

  return `Save & fix ${numberOfActiveTradeoffs} ${pluralize("conflicts", numberOfActiveTradeoffs)}`;
};
const SaveProposalRecurringAndNonRecurringButtons = ({
  disabled,
  isRecurring,
  onSaveNonRecurringEvent,
  onSaveRecurringEvent,
  onConfirmProposal,
  externalEventId,
  proposedStart,
  numberOfActiveTradeoffs,
  saveText,
}: {
  disabled: boolean;
  isRecurring: boolean;
  onSaveNonRecurringEvent: OnSaveNonRecurringEvent;
  onSaveRecurringEvent: OnSaveRecurringEvent;
  onConfirmProposal: OnConfirmProposal;
  externalEventId: string;
  proposedStart: string;
  numberOfActiveTradeoffs: number;
  saveText: string;
}) => {
  const isSBM = numberOfActiveTradeoffs > 0;
  return (
    <div className="cw-flex cw-gap-2 cw-flex-row">
      {onConfirmProposal && isSBM && (
        <SaveSBMProposalButton
          disabled={disabled}
          onSave={onConfirmProposal}
          text={getSaveAndFixConflictsText(numberOfActiveTradeoffs)}
        />
      )}

      {isRecurring && onSaveRecurringEvent ? (
        <SaveDropdownForRecurringEvent
          disabled={false}
          onSelect={onSaveRecurringEvent}
          submissionCopy={{
            default: saveText,
            singleEventOnly: "Apply to this event only",
            thisAndFutureEvents: "Apply to this and future events",
            allEvents: "Apply to all events",
          }}
          variant="filled"
          sentiment="positive"
        />
      ) : (
        <SaveEventButton
          disabled={false}
          onSaveNonRecurringEvent={onSaveNonRecurringEvent}
          externalEventId={externalEventId}
          proposedStart={proposedStart}
          text={saveText}
        />
      )}
    </div>
  );
};

export const RescheduleConfirmationModal = ({
  externalEventId,
  title,
  onClose,
  duration,
  newDuration,
  proposedStart,
  existingStart,
  onSaveNonRecurringEvent,
  onSaveRecurringEvent,
  onConfirmProposal,
  isOpen,
  submitRescheduleMessage,
  tradeoffBlocks = [],
  disabled = false,
  isRecurring = false,
  isOnDNDProposal = false,
  loading = false,
  isHold = false,
}: Props) => {
  // Dev Note: only FixableConflict tradeoffs with diffId are considered active
  // And this number reduces if user removes a tradeoff.
  // The diffId is removed when user removes a tradeoff
  const numberOfActiveTradeoffs = tradeoffBlocks
    .filter((block) => block.tradeoffType === TradeoffType.FixableConflict)
    .flatMap((block) => block.tradeoffs)
    .filter((tradeoff) => tradeoff.diffId).length;
  const saveText = getSaveButtonText(isHold);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      PaperProps={{ className: "cw-w-[400px] cw-min-h-[400px] cw-rounded-xl" }}
      BackdropProps={{
        classes: { root: "cw-bg-[#000000] cw-bg-opacity-30" },
      }}
    >
      <div className="cw-flex cw-flex-col cw-body-sm cw-grow">
        <div
          className={`cw-flex cw-justify-between cw-items-center cw-w-full
                      cw-pl-4 cw-pr-3 cw-pt-[1px] cw-h-[30px]
                      cw-bg-neutral cw-border-b cw-border-solid cw-border-subtle
                      cw-font-semibold cw-text-default`}
        >
          <span>Move event</span>
          <Button
            size="mini"
            aria-label="Collapse"
            variant="text"
            sentiment="neutral"
            startIcon={Close}
            onClick={onClose}
          />
        </div>
        <div className="cw-flex-col cw-pt-4 cw-px-4 cw-pb-6 cw-grow cw-max-h-[550px] cw-overflow-y-scroll">
          <EventThread spacing="Compact">
            <EventThread.Source>
              <div
                className={
                  "cw-border cw-border-solid cw-border-subtle cw-bg-default cw-rounded-xl cw-px-3 cw-py-2"
                }
              >
                <div className="cw-font-bold cw-py-1"> {title}</div>
                <ProposedTime start={proposedStart} duration={newDuration || duration} />
                <OriginalTime start={existingStart} duration={duration} />
              </div>
            </EventThread.Source>
            <TradeoffBlocks tradeoffBlocks={tradeoffBlocks} loading={loading} />
          </EventThread>
        </div>

        <div
          className={classNames(
            "cw-flex cw-w-full cw-border-t cw-border-solid cw-border-subtle cw-py-2 cw-px-3",
            {
              "cw-justify-between": submitRescheduleMessage,
              "cw-justify-end": !submitRescheduleMessage,
            },
          )}
        >
          {submitRescheduleMessage && (
            <FindMoreTimesButton
              shouldExcludeAi={false}
              submitRescheduleMessage={submitRescheduleMessage}
              disabled={false}
              componentType="Button"
              canModify={true}
            />
          )}
          {isOnDNDProposal ? (
            <SaveProposalRecurringAndNonRecurringButtons
              disabled={disabled || loading}
              onSaveNonRecurringEvent={onSaveNonRecurringEvent}
              onSaveRecurringEvent={onSaveRecurringEvent}
              onConfirmProposal={onConfirmProposal}
              isRecurring={isRecurring}
              externalEventId={externalEventId}
              proposedStart={proposedStart}
              numberOfActiveTradeoffs={numberOfActiveTradeoffs}
              saveText={saveText}
            />
          ) : (
            <SaveEventButton
              disabled={disabled || loading}
              onSaveNonRecurringEvent={onSaveNonRecurringEvent}
              externalEventId={externalEventId}
              proposedStart={proposedStart}
              text={saveText}
            />
          )}
        </div>
      </div>
    </Dialog>
  );
};

const ProposedTime = ({ start, duration }: { start: string; duration: number }) => {
  const endTime = DateTime.fromISO(start).plus({ minutes: duration }).toISO();
  const dateString = getFormattedDateRange({ start, end: endTime });
  return (
    <div className="cw-flex cw-body-sm cw-w-full cw-justify-between">
      <div className="cw-w-[240px]"> {dateString} </div>
      <div className="cw-text-12 cw-text-subtle-disabled cw-pl-2"> New </div>
    </div>
  );
};

const OriginalTime = ({ start, duration }: { start: string; duration: number }) => {
  const endTime = DateTime.fromISO(start).plus({ minutes: duration }).toISO();
  const dateString = getFormattedDateRange({ start, end: endTime });
  return (
    <div className="cw-flex cw-body-sm cw-w-full cw-justify-between">
      <div className="cw-w-[240px] cw-text-subtle cw-line-through"> {dateString} </div>
      <div className="cw-text-12 cw-text-subtle-disabled cw-pl-2"> Original</div>
    </div>
  );
};
