import { useFeatureFlag } from "#webapp/src/launch-darkly/useLaunchDarkly";
import { removeCalendarIds } from "#webapp/src/state/actions/multi-calendar.actions";
import { isImpersonated } from "@clockwise/client-commons/src/util/jwt";
import { Divider } from "@clockwise/design-system";
import { EcosystemEnum } from "@clockwise/schema";
import { ProposalState } from "@clockwise/schema/v2";
import { useGatewayQuery } from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { useUpdateActiveEvent } from "@clockwise/web-commons/src/util/ActiveEventContext";
import { TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import { useEcosystem } from "@clockwise/web-commons/src/util/ecosystem";
import { jwt } from "@clockwise/web-commons/src/util/local-storage";
import { compact } from "lodash";
import { Interval } from "luxon";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  NEW_EVENT_PERMISSIONS,
  useCurrentProposal,
  useUpdateCurrentProposal,
} from "../../chat-plus-calendar/CurrentProposalContext";
import {
  usePersistedProposal,
  useUpdatePersistedProposal,
} from "../../chat-plus-calendar/PersistedProposalContext";
import { useUpdateHoverEvent } from "../../chat-plus-calendar/util/HoverEventContext";
import { useUpdateProposalOptionsOverlayV2 } from "../../chat-plus-calendar/util/ProposalOptionsOverlayContextV2";
import { TradeoffBlocks } from "../../tradeoffs/TradeoffBlocks";
import { CardWrapper } from "../CardWrapper";
import { ECAgenda } from "../molecules/ECAgenda";
import { ECCalendarSelect } from "../molecules/ECCalendarSelect";
import { ECLocation } from "../molecules/ECLocation";
import { ECRecurrence } from "../molecules/ECRecurrence";
import { ECHeadTab, ECTabGroup, ECTabList, ECTabPanel, ECTabPanels } from "../molecules/ECTabs";
import { ECTitle } from "../molecules/ECTitle";
import { CalendarInfoDocument } from "../molecules/__generated__/CalendarInfo.v2.generated";
import { TimeSuggestions } from "../molecules/find-time/TimeSuggestions";
import { AllDayToggle } from "./AllDayToggle";
import { AttendeePanel } from "./AttendeePanel";
import { ConferenceTypeSelector } from "./ConferenceTypeSelector";
import { MoreEventTimeOptions, MoreSettings } from "./MoreOptions";
import { ProposalFooter } from "./ProposalFooter";
import { ProposalRefine } from "./ProposalRefine";
import { TimeIntervalAndFlexSelector } from "./TimeIntervalAndFlexSelector";
import { CurrentProposalMembersDocument } from "./__generated__/CurrentProposalMembers.v2.generated";

const Header = ({
  eventId,
  timeSuggestionsTabEnabled,
}: {
  eventId?: string;
  timeSuggestionsTabEnabled: boolean;
}) => {
  return (
    <>
      <ECTabList>
        <ECHeadTab>Event</ECHeadTab>
        {timeSuggestionsTabEnabled && <ECHeadTab>Find time</ECHeadTab>}
        {eventId && <ECHeadTab>History</ECHeadTab>}
      </ECTabList>
    </>
  );
};

export const ProposalSchedulingCard = () => {
  const ecosystem = useEcosystem();
  const dispatch = useDispatch();
  const titleRef = useRef<HTMLInputElement>(null);
  const track = useTracking();
  const [error, setError] = useState<string | null>(null);
  const { currentProposal } = useCurrentProposal();
  const { status: proposalStatus, tradeoffBlocks, suggestedTimeOptions } = usePersistedProposal();
  const updateActiveEvent = useUpdateActiveEvent();
  const updateHoverEvent = useUpdateHoverEvent();
  const [timeSuggestionsTabEnabled] = useFeatureFlag("TimeSuggestionsTab");
  const [tabIndex, setTabIndex] = useState(() => {
    return currentProposal.meta.isFindATime ? 1 : 0;
  });

  const {
    setIsEnabled: setOverlayIsEnabled,
    setIsVisible: setOverlayIsVisible,
  } = useUpdateProposalOptionsOverlayV2();

  const {
    updateTime,
    updateDescription,
    updateAllDay,
    updateTitle,
    addAttendee,
    removeAttendee,
    setAttendees,
    updateAttendeeOptionality,
    updateRecurrenceRule,
    updateConferenceType,
    updateLocation,
    updateCalendar,
    updateSearchTimeRanges,
  } = useUpdateCurrentProposal();
  const { discardPersistedProposal } = useUpdatePersistedProposal();
  const { setHoveredSuggestion } = useUpdateProposalOptionsOverlayV2();

  const {
    title,
    description,
    location,
    startTime,
    endTime,
    allDay,
    attendees,
    conferenceType,
    initialAttendeeIds,
    eventId,
    eventCalendarId,
    searchTimeRanges,
  } = currentProposal;

  const { loading: loadingMembers } = useGatewayQuery(CurrentProposalMembersDocument, {
    variables: {
      calendarIds: initialAttendeeIds,
    },
    // We will fetch and set members directly from the event if opening an existing event
    skip: !currentProposal.initialized || !!eventId,
    onCompleted(data) {
      const displayAttendees = compact(data?.members).map((m) => ({
        isOptional: false,
        isOrganizer: m.person.isMe,
        responseStatus: null,
        ...m,
      }));
      setAttendees(displayAttendees);
    },
  });
  const { data: eventCalendarData, loading: loadingEventCalendarData } = useGatewayQuery(
    CalendarInfoDocument,
    {
      variables: { id: eventCalendarId ?? "" },
    },
  );

  useEffect(() => {
    // re-evaluate the tab index if the eventId changes
    setTabIndex(currentProposal.meta.isFindATime ? 1 : 0);
  }, [eventId]);

  useEffect(() => {
    const calendarInfo = eventCalendarData?.calendar
      ? {
          displayName: eventCalendarData.calendar?.displayName ?? "",
          calendarId: eventCalendarId ?? "",
        }
      : undefined;
    updateCalendar(calendarInfo);
  }, [eventCalendarData]);

  useEffect(() => {
    // Focus the title input when the component mounts
    setTimeout(() => {
      titleRef.current?.focus();
      titleRef.current?.select();
    }, 250);
  }, []);

  if (!currentProposal?.initialized) {
    return <></>;
  }

  const handleClose = () => {
    discardPersistedProposal([
      () => {
        updateActiveEvent(null);
        updateHoverEvent(null);
      },
      () => {
        setOverlayIsEnabled(false);
        dispatch(removeCalendarIds(attendees.map((a) => a.person.email)));
      },
    ]);
  };

  const onTabChange = (index: number) => {
    // if the timeSuggestionsTabEnabled and the index is 1, then we are on the time suggestions tab
    setTabIndex(index);
    if (timeSuggestionsTabEnabled && index === 1) {
      setOverlayIsEnabled(true);
      setOverlayIsVisible(true);
    } else {
      setOverlayIsEnabled(false);
    }
  };

  const isImpersonatedUser = isImpersonated(jwt.get());

  const permissions = NEW_EVENT_PERMISSIONS;
  const readOnly = !permissions.canModify || isImpersonatedUser;

  const isShowingTimeSuggestionsTab = timeSuggestionsTabEnabled && tabIndex === 1;

  return (
    <ECTabGroup defaultIndex={0} selectedIndex={tabIndex} onChange={onTabChange}>
      <CardWrapper
        onClose={handleClose}
        shouldShowRSVP={false}
        header={<Header eventId={eventId} timeSuggestionsTabEnabled={timeSuggestionsTabEnabled} />}
        refineInput={<ProposalRefine />}
        footer={
          <ProposalFooter
            disabled={
              !!error ||
              (ecosystem === EcosystemEnum.Microsoft && currentProposal.attendees.length === 0) ||
              (isShowingTimeSuggestionsTab && proposalStatus === ProposalState.LoadingSuggestions)
            }
            permissions={permissions}
            isDescriptionOmitted={false}
            recurrenceRule={currentProposal.recurrenceRule}
          />
        }
      >
        <ECTabPanels>
          <ECTabPanel className="cw-space-y-2 cw-pb-8">
            <ECTitle
              eventName={title}
              readOnly={readOnly}
              onEventNameChange={(e) => {
                updateTitle(e.target.value);
              }}
              ref={titleRef}
            />
            <AttendeePanel
              attendees={attendees}
              permissions={permissions}
              loading={loadingMembers && attendees.length === 0}
              attendeesOmitted={false}
              readOnly={!permissions.canInviteOthers}
              onAddAttendee={addAttendee}
              onRemoveAttendee={removeAttendee}
              onUpdateAttendeeOptionality={updateAttendeeOptionality}
            />
            <div className="cw-mr-4 cw-ml-10">
              <Divider />
            </div>
            <TimeIntervalAndFlexSelector
              startTime={startTime}
              endTime={endTime}
              isAllDay={allDay}
              readOnly={readOnly}
              onChangeTime={(newStart, newEnd) => {
                if (!newStart || !newEnd) return;
                track(TrackingEvents.DIRECT_MANIPULATION.NEW_EVENT_CARD.UPDATED_TIME, {
                  startTime: newStart,
                  endTime: newEnd,
                });
                updateTime(newStart, newEnd);
              }}
              onError={setError}
            />
            <MoreEventTimeOptions recurrenceRule={currentProposal.recurrenceRule} isAllDay={allDay}>
              <ECRecurrence
                recurrenceRule={currentProposal.recurrenceRule}
                date={startTime.toISODate()}
                onSelect={updateRecurrenceRule}
                readOnly={readOnly}
                originalRecurrence={null}
              />
              <AllDayToggle isAllDay={allDay} onChange={updateAllDay} readOnly={readOnly} />
            </MoreEventTimeOptions>

            {/* TODO: eventually we should handle events with no attendees on them (eg. holds created in gcal) */}
            {attendees.length > 0 && proposalStatus && permissions.canModify && (
              <div className="cw-ml-[2.5rem] cw-mr-3">
                <TradeoffBlocks
                  tradeoffBlocks={tradeoffBlocks ?? []}
                  loading={proposalStatus === ProposalState.LoadingSuggestions}
                />
              </div>
            )}
            <MoreSettings>
              <ConferenceTypeSelector
                selected={conferenceType}
                onSelect={updateConferenceType}
                readOnly={readOnly}
              />
              <ECLocation
                readonly={readOnly}
                location={location ?? ""}
                onLocationChange={(location) => {
                  updateLocation(location ?? "");
                }}
              />
              <ECAgenda
                agenda={description}
                onChangeAgenda={(updatedDescription) => {
                  updateDescription(updatedDescription);
                }}
                readOnly={readOnly}
              />
              <ECCalendarSelect
                calendar={{
                  displayName: eventCalendarData?.calendar?.displayName ?? eventCalendarId ?? "",
                  calendarId: eventCalendarId ?? "",
                }}
                readOnly
                loading={loadingEventCalendarData}
              />
            </MoreSettings>
          </ECTabPanel>
          {timeSuggestionsTabEnabled && (
            <ECTabPanel>
              <TimeSuggestions
                title={title}
                anchorDate={startTime}
                searchTimeRanges={searchTimeRanges ?? []}
                onUpdateSearchTimeRanges={updateSearchTimeRanges}
                selectedInterval={Interval.fromDateTimes(startTime, endTime)}
                proposalStatus={proposalStatus}
                suggestedTimeOptions={suggestedTimeOptions ?? []}
                readOnly={false}
                onUpdateTitle={updateTitle}
                onChooseTime={() => {
                  setTabIndex(0);
                }}
                onUpdateTime={(interval: Interval) => {
                  updateTime(interval.start, interval.end);
                }}
                onHoverTimeSuggestion={(interval: Interval | null) => {
                  setHoveredSuggestion(interval?.toISO() ?? null);
                }}
                attendees={attendees}
                onAddAttendee={addAttendee}
                onRemoveAttendee={removeAttendee}
                onUpdateAttendeeOptionality={updateAttendeeOptionality}
                permissions={permissions}
                attendeesOmitted={false}
              />
            </ECTabPanel>
          )}
        </ECTabPanels>
      </CardWrapper>
    </ECTabGroup>
  );
};
