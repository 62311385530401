import { useFeatureFlag } from "#webapp/src/launch-darkly/useLaunchDarkly";
import { useReadActiveEvent } from "@clockwise/web-commons/src/util/ActiveEventContext";
import classNames from "classnames";
import React from "react";
import { useDefragProposal } from "../../chat-plus-calendar/DefragProposalContext";
import { useReadActiveEventDiff } from "../../chat-plus-calendar/util/ActiveDiffContext";
import ErrorBoundary from "../../error-boundary/ErrorBoundary";
import { CardManager } from "../../event-card/CardManager";
import { EventCardOverlay } from "../../event-card/atoms/EventCardOverlay";
import { AIChatBox } from "./AIChatBox";
import { AIChatFeed } from "./AIChatFeed";
import { AIChatToolbar } from "./components/AIChatToolbar";
import { DiffEventCardExpanded } from "./components/diffs/diff-event-card-expanded";
import { PlanYourWeekWrapper } from "./components/on-demand-defrag/PlanYourWeekWrapper";
import { DefaultExpandedTradeoffProvider } from "./hooks/ExpandedTradeoffContext";

export const AIChatLayout = () => {
  const activeEvent = useReadActiveEvent();
  const activeDiff = useReadActiveEventDiff();
  const { onDemandDefrag } = useDefragProposal();
  const [onDemandDefragAvailable] = useFeatureFlag("onDemandDefrag");

  const showOverlay = activeEvent || activeDiff?.id;

  // TODO, this location of the Defrag wrapper is temporary, we need to figure out if ODD remains in proposals or not
  const showOnDemandDefrag =
    ((onDemandDefrag.visible && onDemandDefrag?.proposal?.id) || onDemandDefrag.loading) &&
    onDemandDefragAvailable;

  return (
    <DefaultExpandedTradeoffProvider initValue={null}>
      <div className="cw-relative cw-h-full cw-w-full cw-bg-neutral">
        {showOverlay && <EventCardOverlay />}
        {showOnDemandDefrag ? (
          <PlanYourWeekWrapper />
        ) : (
          activeEvent && <CardManager activeEvent={activeEvent} />
        )}
        {activeDiff?.id && <DiffEventCardExpanded id={activeDiff.id} />}
        <div className="cw-absolute cw-top-0 cw-right-0 cw-left-0 cw-z-[1] cw-h-16">
          <AIChatToolbar />
        </div>
        <div className="cw-w-full cw-body-base cw-flex-col cw-overflow-y-scroll cw-h-full cw-relative cw-pt-16">
          <div className="cw-w-full cw-min-h-full cw-flex-1 cw-flex cw-flex-col cw-align-center">
            <div
              className={classNames(
                "cw-flex-1 cw-flex cw-flex-col cw-pb-2 cw-px-4 cw-w-full cw-h-full cw-z-[0] cw-overflow-x-hidden",
              )}
            >
              <ErrorBoundary>
                <AIChatFeed />
              </ErrorBoundary>
            </div>
            <AIChatBox />
          </div>
        </div>
      </div>
    </DefaultExpandedTradeoffProvider>
  );
};
