import { Button } from "@clockwise/design-system";
import { KeyboardArrowDown, KeyboardArrowUp } from "@clockwise/icons";
import { noop } from "lodash";
import { DateTime } from "luxon";
import React, { useMemo, useState } from "react";
import { useUpdateDefragProposal } from "../../../../chat-plus-calendar/DefragProposalContext";
import { optimizeOptionIcon } from "../../ChatInput/Icons";
import { CalloutCard } from "../CalloutCard";
import { DefaultWeights } from "./constants";
import { PreferencesTuner } from "./PreferencesTuner";

const getStartAndEnd = (date: DateTime) => {
  // If wednesday or later, plan for next week
  if (date.weekday >= 3) {
    return {
      start: date.startOf("week").plus({ days: 7 }),
      end: date.endOf("week").plus({ days: 7 }),
    };
  }
  return { start: date.startOf("week"), end: date.endOf("week") };
};

export const PlanMyWeek = () => {
  const [expanded, setExpanded] = useState(false);

  const { fetchPlanMyWeek } = useUpdateDefragProposal();
  const [preferencesValues, setPreferencesValues] = useState(DefaultWeights);

  const onClick = () => {
    const { start, end } = getStartAndEnd(DateTime.now());
    fetchPlanMyWeek(preferencesValues, start, end);
    setExpanded(false);
  };

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const updatePreference = (key: keyof typeof DefaultWeights, value: number) => {
    setPreferencesValues((prev) => ({ ...prev, [key]: value }));
  };

  const resetPreferences = () => {
    setPreferencesValues(DefaultWeights);
  };

  // This loading state is temporary
  const buttons = useMemo(
    () => [
      {
        text: "Plan week",
        onClick: onClick,
        icon: optimizeOptionIcon,
      },
      {
        text: "Edit",
        icon: expanded ? KeyboardArrowUp : KeyboardArrowDown,
        onClick: toggleExpanded,
      },
    ],
    [toggleExpanded, expanded],
  );

  const gradientColor =
    "linear-gradient(154.72deg, #DCD5F4 29.68%, #DAB0F1 53.17%, #FFC1A4 91.98%)";

  const isWednesdayOrLater = DateTime.now().weekday >= 3;

  return (
    <div className="cw-flex cw-flex-col cw-bg-neutral-inset cw-rounded-md">
      <CalloutCard
        color={gradientColor}
        onClick={noop}
        subText={
          isWednesdayOrLater
            ? "Take a moment to plan for next week"
            : "Take a moment to plan your week"
        }
        buttons={buttons}
        showHoverState={false}
      />
      {expanded && (
        <div className="cw-flex cw-flex-col cw-gap-y-3 cw-px-2 cw-pb-2">
          <PreferencesTuner values={preferencesValues} onChange={updatePreference} />
          <div className="cw-flex cw-justify-end">
            <Button sentiment="neutral" variant="outlined" onClick={resetPreferences} size="mini">
              Reset
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
