import { Skeleton } from "@clockwise/design-system";
import React from "react";

export const Title = ({
  loading = false,
  onClick,
  subTitle,
  title,
}: {
  title: string;
  subTitle?: string;
  onClick: () => void;
  loading?: boolean;
}) => {
  return (
    <div
      className="cw-px-2 cw-pt-1 cw-my-1 cw-pb-1 cw-rounded-lg cw-cursor-pointer hover:cw-bg-default-hover"
      onClick={onClick}
    >
      <div className="cw-flex cw-items-center cw-justify-between cw-gap-[3px] cw-overflow-hidden cw-whitespace-nowrap cw-text-ellipsis cw-w-full">
        <span className="cw-overflow-hidden cw-whitespace-nowrap cw-text-ellipsis cw-body-base cw-text-14 cw-font-semibold cw-text-default">
          {loading ? <Skeleton className="cw-rounded-md" width={200} height={16} /> : title}
        </span>
      </div>
      {subTitle && <span className="cw-body-sm cw-text-12 cw-text-subtle">{subTitle}</span>}
    </div>
  );
};
