import { Skeleton } from "@clockwise/design-system";
import { fg_warning_muted } from "@clockwise/design-system/tokens";
import {
  CheckCircleFilled,
  ClockwiseIntelligenceFilled,
  EmergencyHomeFilled,
  SentimentNeutralFilled,
} from "@clockwise/icons";
import { CalendarPositioner } from "@clockwise/web-commons/src/components/calendar";
import { ICalPositionable } from "@clockwise/web-commons/src/components/calendar/calendar-positioner/types";
import { AttendeeAvatar } from "@clockwise/web-commons/src/ui/AttendeeAvatar";
import { getFullName } from "@clockwise/web-commons/src/util/profile.util";
import classNames from "classnames";
import { DateTime, Interval } from "luxon";
import React, { useMemo } from "react";
import { useReadTimeSuggestionPeek } from "../../chat-plus-calendar/util/TimeSuggestionPeekContext";
import { SchedulingOptionsTradeoffs } from "../../chat/ai-chat/utils/types";
import { TradeoffsPopover } from "../../tradeoffs-popover";
import { getDateStringKeyFromDateTime } from "../calendar-single-day/utils/getDateStringKeyFromDateTime";
import { TradeoffUIVariant } from "../calendar-single-day/utils/mapCalendarIdToTradeoffUIVariant";
import { useActiveProposalTradeoffDetails } from "../calendar-single-day/utils/useActiveProposalTradeoffDetails";
import { usePlannerContext } from "../Context";
import { useReadCalendarColors } from "../hooks/CalendarColorsContext";
import {
  CalendarProfile,
  useCalendarProfilesByCalendarId,
} from "../multi-calendar-select/util/MultiCalendarSelect.util";
import useCurrentDateTime from "../planner-datetime-marker/hooks/useCurrentDateTime";
import { filterTradeoffsForUser } from "./utils";

const getProfileForCalendarId = (profilesForCalendarIds: CalendarProfile[], calendarId: string) =>
  profilesForCalendarIds.find((profile) => profile.primaryCalendar === calendarId);

export const UserColumnHeader = ({
  calendarIds,
  dateTime,
  orgId,
  timeZones,
  minWidthPercent,
  showBorder,
}: {
  calendarIds: string[];
  dateTime: DateTime;
  orgId: string;
  timeZones: Record<string, string | null>;
  minWidthPercent: number;
  showBorder: boolean;
}) => {
  const { calendarProfiles } = useCalendarProfilesByCalendarId(orgId, calendarIds);

  const profiles = useMemo(() => calendarProfiles ?? [], [calendarProfiles]);

  return (
    <div className="cw-flex cw-flex-row cw-bg-default cw-h-full">
      <UserProfiles
        profiles={profiles}
        calendarIds={calendarIds}
        timeZones={timeZones}
        minWidth={minWidthPercent}
        dateTime={dateTime}
        showBorder={showBorder}
      />
    </div>
  );
};

const UserProfiles = ({
  profiles,
  calendarIds,
  timeZones,
  minWidth,
  dateTime,
  showBorder,
}: {
  profiles: CalendarProfile[];
  calendarIds: string[];
  timeZones: Record<string, string | null>;
  minWidth: number;
  dateTime: DateTime;
  showBorder: boolean;
}) => {
  const {
    tradeoffBlockToCalendarIdMap,
    activeTradeoffs,
    loading,
  } = useActiveProposalTradeoffDetails();

  const calendarColors = useReadCalendarColors();

  return (
    <div className="cw-flex cw-w-full cw-h-full cw-relative">
      {calendarIds.map((calendarId, index) => {
        const profile = getProfileForCalendarId(profiles, calendarId);
        const zone = timeZones[calendarId];
        const activeTradeoffsForUser = filterTradeoffsForUser(activeTradeoffs, calendarId);

        return (
          <UserProfile
            key={calendarId}
            calendarId={calendarId}
            profile={profile}
            zone={zone}
            index={index}
            columnCount={calendarIds.length}
            dateTime={dateTime}
            borderColor={calendarColors[calendarId]?.border}
            minWidth={minWidth}
            showBorder={showBorder}
            tradeoffUIVariant={tradeoffBlockToCalendarIdMap[calendarId] ?? undefined}
            tradeoffs={activeTradeoffsForUser}
            loading={loading}
          />
        );
      })}
    </div>
  );
};

const UserProfile = ({
  profile,
  calendarId,
  zone,
  borderColor,
  dateTime,
  index,
  columnCount,
  minWidth,
  showBorder,
  tradeoffUIVariant,
  tradeoffs,
  loading,
}: {
  calendarId: string;
  zone: string | null;
  borderColor?: string;
  profile?: CalendarProfile;
  dateTime: DateTime;
  index: number;
  columnCount: number;
  minWidth: number;
  showBorder: boolean;
  tradeoffUIVariant?: TradeoffUIVariant;
  tradeoffs: SchedulingOptionsTradeoffs;
  loading: boolean;
}) => {
  const tradeoffsPopoverAnchorRef = React.useRef<HTMLDivElement | null>(null);
  const [showTradeoffsPopover, setShowTradeoffsPopover] = React.useState(false);

  if (!profile) {
    return null;
  }

  const fullName = getFullName(profile.profile);
  const positionables: ICalPositionable[] = [
    {
      key: `${calendarId}-single-day-head`,
      interval: Interval.fromDateTimes(
        dateTime.startOf("day"),
        dateTime.startOf("day").plus({ hours: 2 }),
      ),
      render: () => (
        <div
          className={classNames("cw-flex cw-flex-col cw-flex-1 cw-min-w-0 cw-items-center", {
            "cw-border-b cw-border-subtle cw-border-solid": showBorder,
          })}
          key={calendarId}
          style={{ height: "50px" }}
        >
          <div className="cw-flex cw-items-center cw-justify-center cw-whitespace-nowrap cw-overflow-hidden cw-w-full">
            <div className="cw-body-base cw-text-13 cw-mr-1 cw-text-ellipsis cw-whitespace-nowrap cw-overflow-hidden">
              {fullName}
            </div>
            {profile.profile && (
              <div className="cw-mr-1">
                <AttendeeAvatar
                  size="medium"
                  profile={profile.profile}
                  borderColor={borderColor ?? "#000000"}
                />
              </div>
            )}
          </div>
          <div
            className="cw-pb-[6px] cw-flex cw-items-center"
            ref={tradeoffsPopoverAnchorRef}
            onMouseEnter={() => {
              if (tradeoffUIVariant) setShowTradeoffsPopover(true);
            }}
            onMouseLeave={() => {
              if (tradeoffUIVariant) setShowTradeoffsPopover(false);
            }}
          >
            {loading ? (
              <>
                <Skeleton width={16} height={16} className="cw-rounded-full cw-mr-0.5" />
                <Skeleton width={65} height={16} />
              </>
            ) : (
              <>
                {tradeoffUIVariant === TradeoffUIVariant.AVAILABILITY_ISSUE && (
                  <EmergencyHomeFilled
                    className="cw-w-4 cw-h-4 cw-mr-0.5"
                    style={{ color: fg_warning_muted }}
                  />
                )}
                {tradeoffUIVariant === TradeoffUIVariant.FIXABLE_CONFLICT && (
                  <ClockwiseIntelligenceFilled className="cw-w-4 cw-h-4 cw-mr-0.5 cw-text-busy" />
                )}
                {tradeoffUIVariant === TradeoffUIVariant.INCONVENIENCE && (
                  <SentimentNeutralFilled className="cw-text-muted cw-w-4 cw-h-4 cw-mr-0.5" />
                )}
                {tradeoffUIVariant === TradeoffUIVariant.NO_TRADEOFFS && (
                  <CheckCircleFilled className="cw-text-positive cw-w-4 cw-h-4 cw-mr-0.5" />
                )}
                <CurrentZonedDateTime
                  zone={zone}
                  tradeoffUIVariant={tradeoffUIVariant}
                  dateTime={dateTime}
                />
              </>
            )}
          </div>

          {showTradeoffsPopover && (
            <TradeoffsPopover
              tradeoffs={tradeoffs}
              side="bottom"
              totalAttendeeCount={1}
              anchorEl={tradeoffsPopoverAnchorRef}
              showAttendeeCount={false}
            />
          )}
        </div>
      ),
    },
  ];

  return (
    <CalendarPositioner
      dateTimes={[dateTime]}
      positionables={positionables}
      columnIndexOverride={index}
      columnCountOverride={columnCount}
      minWidth={minWidth}
    />
  );
};

const CurrentZonedDateTime = ({
  zone,
  tradeoffUIVariant,
  dateTime,
}: {
  zone: string | null;
  tradeoffUIVariant?: TradeoffUIVariant;
  dateTime: DateTime;
}) => {
  const { interval } = useReadTimeSuggestionPeek();

  const currentDateTime = useCurrentDateTime();

  const { eventsByDayAndCalendar } = usePlannerContext();
  const dateToRender = getDateStringKeyFromDateTime(dateTime);

  const eventsByCalendarForDay = useMemo(() => {
    return eventsByDayAndCalendar ? eventsByDayAndCalendar[dateToRender] : null;
  }, [dateToRender, eventsByDayAndCalendar]);

  const proposalEvents = useMemo(() => eventsByCalendarForDay?.proposalEvents ?? [], [
    eventsByCalendarForDay,
  ]);

  if (!zone) {
    return null;
  }

  const hoveredTimeSuggestion = interval ? Interval.fromISO(interval).start : null;

  const getTimeToDisplay = () => {
    if (hoveredTimeSuggestion) {
      return hoveredTimeSuggestion;
    } else if (proposalEvents.length === 1) {
      const proposalEvent = proposalEvents[0];
      return proposalEvent.interval.start;
    }
    return currentDateTime;
  };
  const nowDateTime: DateTime = getTimeToDisplay();
  const nowZoned = nowDateTime.setZone(zone);
  const zoneAbbreviation = nowZoned.toFormat("ZZZZ");

  return (
    <div
      className={classNames(
        "cw-body-sm cw-text-11 cw-mr-2 cw-text-ellipsis cw-whitespace-nowrap cw-overflow-hidden",

        {
          "cw-text-warning": tradeoffUIVariant === TradeoffUIVariant.AVAILABILITY_ISSUE,
          "cw-text-busy": tradeoffUIVariant === TradeoffUIVariant.FIXABLE_CONFLICT,
        },
      )}
    >{`${nowZoned.toFormat("h:mma").toLowerCase()} ${zoneAbbreviation}`}</div>
  );
};
