import { Skeleton } from "@clockwise/design-system";
import React from "react";

const WorkingHoursSkeleton = () => {
  return (
    <div className="cw-flex cw-flex-col">
      <div className="cw-flex cw-mb-3">
        <Skeleton width={26} height={26} className="cw-rounded-full cw-mr-[3px]" />
        <Skeleton width={26} height={26} className="cw-rounded-full cw-mx-[3px]" />
        <Skeleton width={26} height={26} className="cw-rounded-full cw-mx-[3px]" />
        <Skeleton width={26} height={26} className="cw-rounded-full cw-mx-[3px]" />
        <Skeleton width={26} height={26} className="cw-rounded-full cw-mx-[3px]" />
        <Skeleton width={26} height={26} className="cw-rounded-full cw-mx-[3px]" />
        <Skeleton width={26} height={26} className="cw-rounded-full cw-ml-[3px]" />
      </div>
      <Skeleton width="20rem" height="30px" className="cw-mb-4" />
      <Skeleton width="20rem" height="30px" className="cw-mb-4" />
      <Skeleton width="20rem" height="30px" className="cw-mb-4" />
      <Skeleton width="20rem" height="30px" className="cw-mb-4" />
      <Skeleton width="20rem" height="30px" className="cw-mb-4" />
    </div>
  );
};

export default WorkingHoursSkeleton;
