import { useLazyQuery } from "@apollo/client";
import { Button, Skeleton, TextField } from "@clockwise/design-system";
import React, { useState } from "react";
import { ClickToCopyIdSpan } from "../sudo-modify-user/SudoModifyUser";
import { SudoLookupOrgIdDocument } from "./graphql/__generated__/SudoLookupOrgId.generated";

export const OrgLookup = () => {
  const [domainValue, setDomainValue] = useState<string | undefined>();

  const [lookupOrgId, { loading, data }] = useLazyQuery(SudoLookupOrgIdDocument);

  const onClickSearch = () => {
    if (domainValue) {
      void lookupOrgId({ variables: { domain: domainValue } });
    }
  };

  const orgId = data?.viewer?.sudo?.orgIdFromDomain;

  return (
    <div className="cw-flex cw-flex-col cw-gap-2">
      <div className="cw-heading-lg">Org Lookup </div>
      <div className="cw-flex cw-gap-x-2 cw-items-center cw-mb-2 cw-flex-wrap">
        <TextField
          label="Domain"
          value={domainValue}
          onChange={(e) => setDomainValue(e.target.value)}
          placeholder="ex: getclockwise.com"
        />

        <div className="cw-pt-[14px]">
          <Button
            sentiment={"positive"}
            onClick={onClickSearch}
            size="small"
            fullWidth={false}
            disabled={!domainValue}
          >
            {"Find OrgId"}
          </Button>
        </div>
      </div>
      <div className="cw-flex cw-py-2">
        {loading && <Skeleton height={30} width={250} />}
        {orgId && (
          <div className="cw-flex cw-items-center cw-flex-wrap">
            <div className="cw-font-semibold cw-mr-1">Org ID:</div>
            <ClickToCopyIdSpan>{orgId}</ClickToCopyIdSpan>
          </div>
        )}
      </div>
    </div>
  );
};
