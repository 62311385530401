import { useQuery } from "@apollo/client";
import { getValue } from "@clockwise/client-commons/src/util/errorable.util";
import { Skeleton } from "@clockwise/design-system";
import React from "react";
import { getCurrentOrg } from "../../util/org.util";
import ListItem from "./ListItem";
import { PersonalDataSourcesDocument } from "./graphql/__generated__/PersonalDataSources.generated";
import { DataSource } from "./types";
import byNameInDomainFilter from "./utils/byNameInDomainFilter";
import byNameSorter from "./utils/byNameSorter";
import toDataSourceReducer from "./utils/toDataSourceReducer";

type Props = {
  disabled?: boolean;
  onSaveEnd?: (saveType: "removed") => void;
  onSaveError?: (error?: Error) => void;
  onSaveStart?: () => void;
};

const List = ({ disabled, onSaveEnd, onSaveError, onSaveStart }: Props) => {
  const { data, loading, error } = useQuery(PersonalDataSourcesDocument);

  const org = getCurrentOrg(data?.viewer);
  const orgDomains = org?.domains as string[];

  const dataSourceConnection = getValue(data?.viewer.user?.dataSources, "DataSourceConnection");
  const edges = dataSourceConnection?.edges || [];
  const dataSources = edges
    .reduce<DataSource[]>(toDataSourceReducer, [])
    .filter(byNameInDomainFilter(orgDomains))
    .sort(byNameSorter);

  const handleRemoveSaved = () => onSaveEnd?.("removed");

  if (error) {
    return <div className="cw-text-warning">Unable to render current calendar syncs.</div>;
  }

  if (loading) {
    return <Skeleton width={"12em"} height={16} />;
  }

  if (!dataSources || dataSources.length <= 0) {
    return null;
  }

  return (
    <>
      {dataSources.map((dataSource) => (
        <ListItem
          dataSource={dataSource}
          disabled={disabled}
          key={dataSource.id}
          onSaveEnd={handleRemoveSaved}
          onSaveError={onSaveError}
          onSaveStart={onSaveStart}
        />
      ))}
    </>
  );
};

export default List;
