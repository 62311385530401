import { Skeleton } from "@clockwise/design-system";
import React from "react";

export const LinksDashboardLoadingCard = () => (
  <div
    className="cw-box-border cw-px-4 cw-py-3 cw-rounded-lg cw-shadow-md cw-border-solid cw-border-2 cw-border-neutral cw-bg-neutral cw-animate-pulse cw-w-full cw-h-[178px] cw-flex cw-flex-col cw-gap-6 cw-justify-between"
    role="progressbar"
    aria-label="Loading a Link"
  >
    <div className="cw-flex cw-flex-col cw-gap-4">
      <Skeleton className="cw-rounded-md" width={85} height={24} />
      <Skeleton className="cw-rounded-md" width={190} height={33} />
    </div>
    <div className="cw-inline-flex cw-justify-start cw-align-bottom cw-gap-2">
      <Skeleton className="cw-rounded-md" width={120} height={38} />
      <Skeleton className="cw-rounded-md" width={120} height={38} />
    </div>
  </div>
);
