import { Skeleton, Typography } from "@clockwise/design-system";
import React from "react";
import { paths } from "../../constants/site.constant";
import { A } from "../ui";
import { WebSettingsContainer } from "../web-settings/web-settings-container";

export const PreferencesSkeleton = () => (
  <div>
    <WebSettingsContainer>
      <Typography variant="h1" className="cw-mb-11">
        Preferences
      </Typography>
      <Skeleton height={25} width={550} className="cw-mb-8" />
      <Skeleton height={20} width={600} className="cw-mb-8" />
      {/* working days on/off checkboxes */}
      <div className="cw-flex cw-mb-6">
        <Skeleton height={26} width={26} className="cw-rounded-full cw-mr-1" />
        <Skeleton height={26} width={26} className="cw-rounded-full cw-mr-1" />
        <Skeleton height={26} width={26} className="cw-rounded-full cw-mr-1" />
        <Skeleton height={26} width={26} className="cw-rounded-full cw-mr-1" />
        <Skeleton height={26} width={26} className="cw-rounded-full cw-mr-1" />
        <Skeleton height={26} width={26} className="cw-rounded-full cw-mr-1" />
        <Skeleton height={26} width={26} className="cw-rounded-full cw-mr-1" />
      </div>
      {/* working hours */}
      <div className="cw-mb-11">
        <div className="cw-flex cw-items-center cw-gap-5 cw-mb-2">
          <div className="cw-w-full cw-max-w-[85px]">
            <Skeleton height={18} width={75} />
          </div>
          <Skeleton height={36} width={160} />
          <Skeleton height={24} width={24} className="cw-rounded-full" />
          <Skeleton height={16} width={120} />
        </div>
        <div className="cw-flex cw-items-center cw-gap-5 cw-mb-2">
          <div className="cw-w-full cw-max-w-[85px]">
            <Skeleton height={18} width={68} />
          </div>
          <Skeleton height={36} width={160} />
          <Skeleton height={24} width={24} className="cw-rounded-full" />
        </div>
        <div className="cw-flex cw-items-center cw-gap-5 cw-mb-2">
          <div className="cw-w-full cw-max-w-[85px]">
            <Skeleton height={18} width={83} />
          </div>
          <Skeleton height={36} width={160} />
          <Skeleton height={24} width={24} className="cw-rounded-full" />
        </div>
        <div className="cw-flex cw-items-center cw-gap-5 cw-mb-2">
          <div className="cw-w-full cw-max-w-[85px]">
            <Skeleton height={18} width={70} />
          </div>
          <Skeleton height={36} width={160} />
          <Skeleton height={24} width={24} className="cw-rounded-full" />
        </div>
        <div className="cw-flex cw-items-center cw-gap-5">
          <div className="cw-w-full cw-max-w-[85px]">
            <Skeleton height={18} width={80} />
          </div>
          <Skeleton height={36} width={160} />
          <Skeleton height={24} width={24} className="cw-rounded-full" />
        </div>
      </div>
      {/* Timezone */}
      <div className="cw-mb-16">
        <Skeleton height={25} width={400} className="cw-mb-8" />
        <Skeleton height={40} width={450} />
      </div>
      {/* Meeting Hours */}
      <div className="cw-mb-16">
        <Skeleton height={25} width={500} className="cw-mb-8" />
        <Skeleton height={20} className="cw-w-full cw-mb-3" />
        <Skeleton height={20} width={100} className="cw-mb-6" />
        {/* Checkbox row */}
        <div className="cw-flex cw-items-center cw-gap-3">
          <Skeleton height={20} width={20} />
          <Skeleton height={20} width={650} />
        </div>
      </div>
      {/*  Meeting Time Preference */}
      <div className="cw-mb-16">
        <Skeleton height={25} width={700} className="cw-mb-8" />
        {/* Checkbox row */}
        <div className="cw-flex cw-items-center cw-gap-3 cw-mb-4">
          <Skeleton height={20} width={20} />
          <Skeleton height={20} width={550} />
        </div>
        {/* Checkbox row */}
        <div className="cw-flex cw-items-center cw-gap-3">
          <Skeleton height={20} width={20} />
          <Skeleton height={20} width={600} />
        </div>
      </div>
      {/* Meeting Room Preference */}
      <div className="cw-mb-16">
        <Skeleton height={25} width={350} className="cw-mb-8" />
        <Skeleton height={20} className="cw-w-full cw-mb-6" />
        <div className="cw-flex cw-items-center cw-justify-between cw-w-full cw-mb-6">
          <Skeleton height={35} width={300} />
          <Skeleton height={14} width={80} />
        </div>
        <div className="cw-flex cw-items-center cw-justify-between cw-w-full">
          <Skeleton height={20} width={290} />
          <div className="cw-flex cw-items-center cw-gap-3">
            <Skeleton height={20} width={100} />
            <Skeleton height={20} width={20} />
          </div>
        </div>
      </div>
    </WebSettingsContainer>
  </div>
);

export const WebSettingsEmailsAndNotificationsSkeleton = () => (
  <WebSettingsContainer>
    <Typography variant="h1" className="cw-mb-11">
      Emails and notifications
    </Typography>
    <div className="cw-mb-16">
      <Skeleton height={25} width={250} className="cw-mb-8" />
      {/* Checkbox row */}
      <div className="cw-flex cw-items-center cw-gap-3 cw-mb-4">
        <Skeleton height={20} width={20} />
        <Skeleton height={20} width={550} />
      </div>
      {/* Checkbox row */}
      <div className="cw-flex cw-items-center cw-gap-3 cw-mb-4">
        <Skeleton height={20} width={20} />
        <Skeleton height={20} width={530} />
      </div>
      {/* Checkbox row */}
      <div className="cw-flex cw-items-center cw-gap-3">
        <Skeleton height={20} width={20} />
        <Skeleton height={20} width={350} />
      </div>
    </div>
    <div className="cw-mb-16">
      <Skeleton height={25} width={120} className="cw-mb-8" />
      {/* Checkbox row */}
      <div className="cw-flex cw-items-center cw-gap-3 cw-mb-4">
        <Skeleton height={20} width={20} />
        <Skeleton height={20} width={200} />
      </div>
      {/* Checkbox row */}
      <div className="cw-flex cw-items-center cw-gap-3 cw-mb-4">
        <Skeleton height={20} width={20} />
        <Skeleton height={20} width={330} />
      </div>
    </div>
    <div className="cw-mb-16">
      <Skeleton height={25} width={150} className="cw-mb-8" />
      <Skeleton height={20} width={520} />
    </div>
  </WebSettingsContainer>
);

export const AccountDetailsSkeleton = () => (
  <WebSettingsContainer>
    <div className="cw-py-10">
      <h1 className="cw-heading-3xl cw-m-0 cw-mb-11">Account Details</h1>
      <div className="cw-flex cw-flex-col cw-gap-8">
        <div className="cw-flex cw-items-center cw-justify-between">
          <Skeleton height={30} width={350} />
          <Skeleton height={12} width={250} />
        </div>
        <div>
          <A style={{ marginRight: 15 }} target="_blank" href={paths.terms}>
            Terms of Service
          </A>
          <A style={{ marginRight: 15 }} target="_blank" href={paths.privacy}>
            Privacy Policy
          </A>
        </div>
      </div>
    </div>
  </WebSettingsContainer>
);

export const AccountSettingsSkeleton = () => (
  <div className="cw-min-w-[320px] cw-w-full cw-max-w-screen-lg">
    <PreferencesSkeleton />
    <WebSettingsEmailsAndNotificationsSkeleton />
    <AccountDetailsSkeleton />
  </div>
);
