import { useMonetization } from "#webapp/src/hooks/useMonetization/useMonetization";
import { Frequency } from "@clockwise/client-commons/src/datatypes/RecurrenceRule";
import { Skeleton } from "@clockwise/design-system";
import { flexibilityExpiredTooltipCopy } from "@clockwise/web-commons/src/ui/disabled-wrapper";
import { filter } from "lodash";
import React from "react";
import { EventForFlexibility } from "../util/parseFlexibility";
import { FlexEventCard } from "./FlexEventCard";

const Wrapper = ({ title, children }: { title: string; children: React.ReactNode }) => {
  return (
    <div className="cw-flex cw-h-fit cw-bg-neutral cw-border cw-rounded-xl cw-border-[#F1F1F1] cw-pt-3 cw-pb-5 cw-px-4 cw-border-solid">
      <div className="cw-space-y-3 cw-grow">
        <h3 className="cw-m-0 cw-mr-2 cw-body-lg cw-font-semibold">{title}</h3>
        {children}
      </div>
    </div>
  );
};

export const FlexEventCardColumns = ({
  oneOnOnes,
  loading,
  handleFlexibilityChange,
  fetchError,
}: {
  oneOnOnes: EventForFlexibility[];
  loading: boolean;
  handleFlexibilityChange: (eventId: string, isFlexible: boolean) => void;
  fetchError?: string;
}) => {
  const { canUserAccessFlexMeetings } = useMonetization();

  const weeklyOneOnOnes = filter(oneOnOnes, ({ recurrenceType }) => {
    return recurrenceType === Frequency.WEEKLY;
  });
  const biweeklyOneOnOnes = filter(
    oneOnOnes,
    ({ recurrenceType }) => recurrenceType === Frequency.FORTNIGHTLY,
  );
  const otherOneOnOnes = filter(
    oneOnOnes,
    ({ recurrenceType }) =>
      recurrenceType !== Frequency.WEEKLY && recurrenceType !== Frequency.FORTNIGHTLY,
  );

  if (loading) {
    return (
      <>
        <Wrapper title="Weekly">
          <div className="cw-space-y-3">
            <Skeleton height="55px" className="cw-w-full" />
            <Skeleton height="55px" className="cw-w-full" />
          </div>
        </Wrapper>
        <Wrapper title="Every 2 weeks">
          <div className="cw-space-y-3">
            <Skeleton height="55px" className="cw-w-full" />
          </div>
        </Wrapper>
        <Wrapper title="Other recurrence">
          <div className="cw-space-y-3">
            <Skeleton height="55px" className="cw-w-full" />
          </div>
        </Wrapper>
      </>
    );
  }

  if (fetchError) {
    return (
      <div className="cw-border-0 cw-border-y cw-border-solid cw-border-muted">
        <div className="cw-py-2 cw-text-warning cw-pl-2 cw-font-semibold cw-body-base">
          {fetchError}
        </div>
      </div>
    );
  }

  return (
    <>
      <Wrapper title="Weekly">
        {weeklyOneOnOnes.map((oneOnOne) => {
          return (
            <FlexEventCard
              event={oneOnOne}
              loading={loading}
              editable={true}
              key={oneOnOne.id}
              restrictedReason={
                !oneOnOne.canMarkFlexible
                  ? "You don't have editing rights to this event"
                  : !canUserAccessFlexMeetings
                  ? flexibilityExpiredTooltipCopy.header
                  : undefined
              }
              onFlexibilityChange={handleFlexibilityChange}
            />
          );
        })}
      </Wrapper>
      <Wrapper title="Every 2 weeks">
        {biweeklyOneOnOnes.map((oneOnOne) => {
          return (
            <FlexEventCard
              editable={true}
              loading={loading}
              event={oneOnOne}
              key={oneOnOne.id}
              restrictedReason={
                !oneOnOne.canMarkFlexible
                  ? "You don't have editing rights to this event"
                  : !canUserAccessFlexMeetings
                  ? flexibilityExpiredTooltipCopy.header
                  : undefined
              }
              onFlexibilityChange={handleFlexibilityChange}
            />
          );
        })}
      </Wrapper>
      <Wrapper title="Other recurrence">
        {otherOneOnOnes.map((oneOnOne) => {
          return (
            <FlexEventCard
              editable={true}
              loading={loading}
              event={oneOnOne}
              key={oneOnOne.id}
              restrictedReason={
                !oneOnOne.canMarkFlexible
                  ? "You don't have editing rights to this event"
                  : !canUserAccessFlexMeetings
                  ? flexibilityExpiredTooltipCopy.header
                  : undefined
              }
              onFlexibilityChange={handleFlexibilityChange}
            />
          );
        })}
      </Wrapper>
    </>
  );
};
