import { TranslationContext } from "@clockwise/client-commons/src/providers/TranslationContext";
import { Skeleton, Switch, Tooltip } from "@clockwise/design-system";
import { DisabledWrapper } from "@clockwise/web-commons/src/ui/disabled-wrapper";
import classNames from "classnames";
import { noop } from "lodash";
import React from "react";
import { FlexEventMenu } from "../flex-event-table/FlexEventMenu";
import { EventForFlexibility } from "../util/parseFlexibility";

type EventCardProps = {
  editable?: boolean;
  loading?: boolean;
  onFlexibilityChange?: (eventId: string, isFlexible: boolean) => void;
  updating?: boolean;
  restrictedReason?: string;
  event: EventForFlexibility;
};

export const FlexEventCard = ({
  editable = true,
  loading = false,
  onFlexibilityChange = noop,
  updating = false,
  restrictedReason,
  event,
}: EventCardProps) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const translation = React.useContext(TranslationContext);
  const tooltipText = translation("autopilot-form:toggle-tooltip", { on: !!event.isFlexible });

  const handleFlexibilityChange = (isFlexible: boolean) =>
    onFlexibilityChange(event.eventId, isFlexible);

  return (
    <DisabledWrapper isDisabled={!!restrictedReason} tooltipBody={restrictedReason}>
      <div
        className={classNames(
          "cw-bg-default cw-border cw-border-1 cw-border-solid cw-border-muted cw-rounded-xl cw-grow cw-min-w-[200px] cw-body-base cw-shadow-sm",
          {
            "cw-bg-default": !isOpen,
            "cw-bg-default-hover": isOpen,
          },
        )}
      >
        <div className=" cw-h-fit cw-min-h-0">
          <div className="cw-px-3 cw-py-2 cw-block">
            <>
              <div className="cw-p-0 cw-flex cw-items-center cw-justify-start">
                <span className="cw-body-sm cw-flex-grow cw-font-semibold">
                  {loading && <Skeleton width="80%" />}
                  {!loading && event.title}
                </span>
                {editable && (
                  <Tooltip title={tooltipText}>
                    <Switch
                      checked={event.isFlexible}
                      disabled={loading || updating}
                      onChange={(isFlexible) => handleFlexibilityChange(isFlexible)}
                      sentiment="neutral"
                      size="small"
                      cw-id="event-card-flexibility-toggle"
                    />
                  </Tooltip>
                )}
              </div>
              <div className="cw-flex cw-items-center cw-text-muted cw-text-xs">
                <div className="cw-leading-5">{event.recurrenceText}</div>
              </div>
            </>
          </div>
        </div>
        {event.isFlexible && (
          <div className="cw-bg-neutral cw-flex cw-gap-1 cw-py-2 cw-px-3 cw-border-[#F1F1F1] cw-rounded-b-xl cw-items-center cw-border-t cw-border-solid">
            <span className="cw-text-subtle cw-text-12 cw-font-medium cw-mt-px">Can move</span>
            <FlexEventMenu
              onOpenCallback={(open) => {
                setIsOpen(open);
              }}
              event={event}
              disabled={updating}
            />
          </div>
        )}
      </div>
    </DisabledWrapper>
  );
};
