import { Menu, MenuItem, Skeleton, Tooltip } from "@clockwise/design-system";
import { Close, FindTime, Info, Link } from "@clockwise/design-system/icons";
import { ContentCopy, IosShare, OutgoingMail } from "@clockwise/icons";
import { TradeoffTypeEnum } from "@clockwise/schema";
import { TrackingEvents, track } from "@clockwise/web-commons/src/util/analytics.util";
import { getRenderTimeZone } from "@clockwise/web-commons/src/util/time-zone.util";
import { compact, uniq } from "lodash";
import { DateTime } from "luxon";
import React from "react";
import { renderToString } from "react-dom/server";
import toast from "react-hot-toast";
import copyToClipboard from "../../../../../util/copyToClipboard";
import { formattedDateTime } from "../../../../web-app-calendar/calendar-popover/utils/formattedDateTime";
import {
  GQLAddDiffSummary,
  GQLModifyDiffSummary,
  GQLPerson,
  GQLProfile,
  TradeoffBlock,
} from "../../utils/types";
import { ProposeNewTimeModal } from "../availability/ProposeNewTimeModal";
import { CopyText } from "./CopyText";
import { getCopyTextEmail } from "./getCopyTextEmail";

export type CalDiffMini = {
  id: string;
  updatedTime: string | null;
  currentTime: string;
  title: string;
};

export type ShareSubMenuProps = {
  proposalId: string;
  allAttendeesExceptViewer: GQLPerson[];
  viewerProfile: GQLProfile | null;
  isProposeNewTime: boolean;
  sharedProposalLink: string | null;
  sharedProposalDiff: GQLAddDiffSummary | GQLModifyDiffSummary | null;
  calDiffMinis: CalDiffMini[];
  tradeoffBlocks: TradeoffBlock[];
  selectedTime: DateTime;
  organizer: GQLPerson | null;
  loading: boolean;
  onShareProposal: () => void;
  onShareAllTimes: () => void;
};

export const ShareSubMenu = ({
  proposalId,
  allAttendeesExceptViewer,
  viewerProfile,
  isProposeNewTime,
  sharedProposalLink,
  sharedProposalDiff,
  calDiffMinis,
  tradeoffBlocks,
  selectedTime,
  organizer,
  loading,
  onShareProposal,
  onShareAllTimes,
}: ShareSubMenuProps) => {
  const [proposeNewTimeModalOpen, setProposeNewTimeModalOpen] = React.useState(false);
  const targetTimeZone = getRenderTimeZone();
  const meetingTitle = sharedProposalDiff?.title || null;
  const formattedTime = selectedTime ? formattedDateTime(selectedTime, targetTimeZone, true) : null;
  const isReschedule = sharedProposalDiff?.__typename === "ModifyDiffSummary";
  const viewerGivenName = viewerProfile?.givenName || null;
  const conflictTradeoffs = tradeoffBlocks.filter(
    (tradeoff) => tradeoff.tradeoffType === TradeoffTypeEnum.AVAILABILITY_ISSUE,
  );

  const copyTitle = `${isReschedule ? "Can we move" : "Are you free to meet for"} ${meetingTitle} ${
    isReschedule ? `to` : `on`
  } ${formattedTime}?`;
  const dataLoaded = !loading && sharedProposalLink;

  const onSendToEmail = () => {
    if (dataLoaded) {
      track(TrackingEvents.SHAREABLE_PROPOSAL.SHARE_SUB_MENU.SEND_TO_EMAIL, {
        isProposeNewTime,
      });

      if (isProposeNewTime) {
        setProposeNewTimeModalOpen(true);
      } else {
        const conflictedAttendees = uniq(
          conflictTradeoffs
            .flatMap((tradeoff) => tradeoff.aggregateAttendees)
            .filter((attendee) => !attendee.isYou),
        );
        const recipients = isProposeNewTime
          ? compact([organizer])
          : conflictedAttendees.length > 0
          ? conflictedAttendees
          : allAttendeesExceptViewer;
        const emails = isProposeNewTime
          ? organizer?.primaryEmail
          : conflictedAttendees.length > 0
          ? conflictedAttendees.map((attendee) => attendee.primaryCalendarId).join(", ")
          : allAttendeesExceptViewer.map((attendee) => attendee.primaryEmail).join(", ");

        const body = getCopyTextEmail({
          copyTitle,
          calDiffs: calDiffMinis,
          conflictTradeoffs,
          sharedProposalLink,
          targetTimeZone,
          isProposeNewTime,
          recipientNames: recipients.map((attendee) => attendee.profile?.givenName || "attendee"),
        });
        window.location.href = `mailto:${emails}?subject=${copyTitle}&body=${body}`;
      }
    }
  };

  const onCopyProposal = () => {
    if (dataLoaded) {
      track(TrackingEvents.SHAREABLE_PROPOSAL.SHARE_SUB_MENU.COPY_PROPOSAL, { isProposeNewTime });

      const body = CopyText({
        copyTitle,
        viewerGivenName,
        calDiffs: calDiffMinis,
        conflictTradeoffs,
        sharedProposalLink,
        targetTimeZone,
        isProposeNewTime,
      });
      copyToClipboard(renderToString(body));
      toast((t) => (
        <div>
          <div className="cw-flex cw-justify-between cw-items-center cw-mb-3">
            <span className="cw-font-semibold cw-text-center cw-w-full">
              Text copied to clipboard
            </span>
            <span className="cw-cursor-pointer" onClick={() => toast.dismiss(t.id)}>
              <Close />
            </span>
          </div>

          <div className="cw-flex cw-items-stretch cw-justify-between cw-bg-neutral-inset cw-duration-1000 cw-rounded-lg cw-min-w-min cw-transition-colors cw-p-2">
            {body}
          </div>
        </div>
      ));
    }
  };

  const handleShareClick = () => {
    track(TrackingEvents.SHAREABLE_PROPOSAL.SHARE_TIMES.BUTTON_CLICKED, {
      isProposeNewTime,
    });
    void onShareProposal();
  };

  const handleShareAllTimes = () => {
    track(TrackingEvents.SHAREABLE_PROPOSAL.SHARE_SUB_MENU.SHARE_ALL_TIMES, {
      isProposeNewTime,
    });
    void onShareAllTimes();
  };

  return (
    <div className="cw-font-body cw-max-w-full cw-relative">
      <Menu
        fullWidth={true}
        size="small"
        label={isProposeNewTime ? "Propose new time" : "Share"}
        icon={isProposeNewTime ? FindTime : IosShare}
        showExpandIcon={false}
        onOpen={handleShareClick}
        placement="top-start"
      >
        {dataLoaded ? (
          <>
            <MenuItem key="send-to-email" onClick={onSendToEmail} icon={OutgoingMail}>
              <div className="cw-flex cw-items-center cw-justify-between cw-w-full">
                <div className="cw-pr-6">Send as Email</div>
                <Tooltip
                  placement="right"
                  title="Share this proposal via email to check with attendees if the time works for them before booking."
                >
                  <Info className="cw-text-neutral-disabled cw-h-[14px]" />
                </Tooltip>
              </div>
            </MenuItem>
            <MenuItem key="copy-proposal" onClick={onCopyProposal} icon={ContentCopy}>
              <div className="cw-flex cw-items-center cw-justify-between cw-w-full">
                <div className="cw-pr-6">Copy proposal</div>
                <Tooltip
                  placement="right"
                  title="Copy this proposal to your clipboard and paste it into a chat or email, to check with attendees if the time works.."
                >
                  <Info className="cw-text-neutral-disabled cw-h-[14px]" />
                </Tooltip>
              </div>
            </MenuItem>
            {!isProposeNewTime && (
              <div className="cw-border-t cw-border-solid cw-border-t-muted">
                <MenuItem key="share-all-times" onClick={handleShareAllTimes} icon={Link}>
                  <div className="cw-flex cw-items-center cw-justify-between cw-w-full">
                    <div className="cw-pr-6">Share all available times</div>
                    <Tooltip
                      placement="right"
                      title="Use this to create a link that lets a recipient choose a time that works for this meeting, and book it for everyone."
                    >
                      <Info className="cw-text-neutral-disabled cw-h-[14px]" />
                    </Tooltip>
                  </div>
                </MenuItem>
              </div>
            )}
          </>
        ) : (
          <>
            <MenuItem key="send-to-email">
              <Skeleton width="200px" />
            </MenuItem>
            <MenuItem key="copy-proposal">
              <Skeleton width="160px" />
            </MenuItem>
            {!isProposeNewTime && (
              <MenuItem key="share-all-times">
                <Skeleton width="220px" />
              </MenuItem>
            )}
          </>
        )}
      </Menu>

      {proposeNewTimeModalOpen && organizer?.primaryEmail && (
        <ProposeNewTimeModal
          proposalId={proposalId}
          dialogOpen={proposeNewTimeModalOpen}
          onClose={() => setProposeNewTimeModalOpen(false)}
          recipientEmail={organizer?.primaryEmail}
        />
      )}
    </div>
  );
};
