import { RouteRenderer } from "#webapp/src/components/route-renderer";
import { logger } from "#webapp/src/util/logger.util";
import { paths } from "@clockwise/client-commons/src/constants/site";
import { LinksDashboardLoadingCard } from "@clockwise/web-commons/src/ui/links-dashboard-loading-card";
import CreateLinkCard from "@clockwise/web-commons/src/ui/scheduling-links/CreateLinkCard";
import { LinkAdminCard } from "@clockwise/web-commons/src/ui/scheduling-links/LinkAdminCard";
import { UsernameDisplayMode } from "@clockwise/web-commons/src/ui/scheduling-links/UsernameEditor";
import { TrialProBadgeWithTooltip } from "@clockwise/web-commons/src/ui/trial-pro-badge-with-tooltip";
import React, { ReactNode, useEffect, useMemo, useState } from "react";

// tracking
import { Button, Skeleton } from "@clockwise/design-system";
import { Settings } from "@clockwise/icons";
import {
  useGatewayMutation,
  useGatewayQuery,
} from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { PaywallBannerUpgradeCta } from "@clockwise/web-commons/src/ui/paywall-banner-upgrade-cta";
import {
  TrialDialogOpenStates,
  TrialUpgradeDialog,
} from "@clockwise/web-commons/src/ui/trial-upgrade-dialog";
import {
  TrackingEvents,
  track,
  useTrackOnce,
} from "@clockwise/web-commons/src/util/analytics.util";
import { getEditSchedulingLinkPath } from "@clockwise/web-commons/src/util/scheduling.util";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useMonetization } from "../../hooks/useMonetization";
import { useFeatureFlag } from "../../launch-darkly";
import { DeleteSchedulingLinkMutationDocument } from "../scheduling-link/__generated__/EditSchedulingLink.v2.generated";
import { ShareBestTimesLinkModal } from "../scheduling-link/ShareBestTimesLinkModal";
import { getLinksByType } from "../scheduling-link/utils";
import { CloneLinkDocument } from "./__generated__/CloneLink.v2.generated";
import { SchedulingLinksQueryDocument } from "./__generated__/SchedulingLinksQuery.v2.generated";
import { UpdateLinkActiveDocument } from "./__generated__/UpdateLinkActive.v2.generated";
import { SchedulingLinkSettingsModal } from "./scheduling-link-settings/SchedulingLinkSettingsModal";

interface ShareTimesLinkData {
  url: string;
  linkName: string;
  name: string;
  slug: string;
  durations: number[];
  defaultDuration: number;
  multipleUser: boolean;
}

export default function SchedulingLinkDashboard() {
  const navigate = useNavigate();
  const [showClone] = useFeatureFlag("LinksClone");
  const [view, setView] = useState<"settings" | "dashboard">("dashboard");
  const [monetizationModalOpen, setMonetizationModalOpen] = useState<TrialDialogOpenStates>(null);
  const [shareTimesLink, setShareTimesLink] = useState<ShareTimesLinkData>();
  const trackViewDashboard = useTrackOnce(TrackingEvents.LINKS.DASHBOARD.VIEW);
  const {
    canUserMarkLinkAsActive,
    canUserSeeLinksTeamsBadge,
    daysLeftInTrial,
    getCopyForMonetizationBadges,
    showLinksMonetizationBanner,
    showRoundRobinMonetizationBanner,
    canUserMakeGroupLinks,
    shouldUserSeeJoinExistingPlan,
  } = useMonetization();

  /*
     Mutations
  */
  const [updateLinkActive, { error: updateLinkError }] = useGatewayMutation(
    UpdateLinkActiveDocument,
  );
  const [cloneLink] = useGatewayMutation(CloneLinkDocument, {
    refetchQueries: [SchedulingLinksQueryDocument],
    awaitRefetchQueries: true,
    onError: (error) => {
      toast.error("There was an error cloning the link.");
      logger.error(`Clone link error: ${error?.message}`, error);
    },
  });

  const [deleteLink, { loading: deletingLink }] = useGatewayMutation(
    DeleteSchedulingLinkMutationDocument,
    {
      refetchQueries: [{ query: SchedulingLinksQueryDocument }],
      update(cache, { data }, { variables }) {
        if (data?.deleteSchedulingLink && variables?.id) {
          cache.evict({ id: cache.identify({ __typename: "SchedulingLink", id: variables.id }) });
          cache.gc();
        }
      },
      onError: (error) => {
        toast.error("There was an error deleting the link.");
        logger.error(`Delete link error: ${error?.message}`, error);
      },
      onCompleted: () => {
        toast.success("Your link has been deleted.");
      },
    },
  );

  /*
     Queries
  */
  const { data, loading, error } = useGatewayQuery(SchedulingLinksQueryDocument);

  if (error) {
    logger.error(error.message);
  }

  const currentUserLinkName = data?.currentUser?.linkName ?? "";
  const schedulingLinks = useMemo(() => data?.currentUser?.schedulingLinks ?? [], [
    data?.currentUser?.schedulingLinks,
  ]);

  let logoStatus: ReactNode;
  if (data) {
    const hasLogo = !!data.currentUser?.resolvedLinkLogoUrl;
    logoStatus = (
      <span className="cw-text-subtle cw-italic cw-whitespace-nowrap">
        {hasLogo ? "active" : "not uploaded"}
      </span>
    );
  } else {
    logoStatus = <Skeleton className="cw-ml-0.5 cw-mb-1" width={120} height={30} />;
  }

  useEffect(() => {
    if (schedulingLinks && !loading) {
      // If making changes here, ensure tracking event fires just once.
      trackViewDashboard({
        linkCount: schedulingLinks.length,
      });
    }
  }, [schedulingLinks, loading, trackViewDashboard]);

  const handleShareTimes = ({
    defaultDuration,
    durations,
    linkMembers,
    name,
    slug,
    linkName,
    url,
  }: {
    defaultDuration: number;
    durations: number[];
    name: string;
    slug: string;
    linkMembers: unknown[];
    linkName: string;
    url: string;
  }) => {
    setShareTimesLink({
      defaultDuration,
      durations,
      linkName,
      multipleUser: linkMembers.length > 1,
      name,
      slug,
      url,
    });
  };

  const onChangeActive = (slug: string, linkType: "1:1" | "Group", linkName: string) => async (
    active: boolean,
  ) => {
    // Show modal and block if users monetization does not allow for current link activation
    if (active && !canUserMarkLinkAsActive(linkType)) {
      setMonetizationModalOpen("Group");
      track(TrackingEvents.PAYWALLS.M2_LINKS_DASHBOARD_PAYWALL_MODAL_OPEN_GROUP);
      return;
    }

    try {
      const { data } = await updateLinkActive({ variables: { slug, active, linkName } });
      if (!data) {
        logger.error("There was an error updating the active status of the link", updateLinkError);
      }
    } catch (error) {
      logger.error("There was an error updating the active status of the link", error);
    }
  };

  const onCreateLink = (groupLink = false, singleUse = false) => {
    // block if user cannot make group links
    if (groupLink && !canUserMakeGroupLinks) {
      setMonetizationModalOpen("Group");
      track(TrackingEvents.PAYWALLS.M2_LINKS_DASHBOARD_PAYWALL_MODAL_OPEN_GROUP);

      return;
    }
    if (singleUse) {
      navigate(paths.createSchedulingLink + "?single_use=true");
    } else {
      navigate(paths.createSchedulingLink + (groupLink ? "?group_link=true" : ""));
    }
  };

  const onEditLink = (linkName: string, slug: string) =>
    navigate(getEditSchedulingLinkPath(linkName, slug));

  const onCloneLink = async (linkName: string, slug: string) => {
    const result = await cloneLink({ variables: { linkName, slug } });
    const newSlug = result.data?.duplicateSchedulingLink.slug;

    if (newSlug) {
      navigate(getEditSchedulingLinkPath(currentUserLinkName, newSlug));
      track(TrackingEvents.LINKS.DASHBOARD.CLONE);
    }
  };

  const onDeleteLink = async (meetingSettingsId: string) => {
    await deleteLink({
      variables: {
        id: meetingSettingsId,
      },
    });

    track(TrackingEvents.LINKS.DASHBOARD.DELETE, { meetingSettingsId });
  };

  const onClickLinkCard = (linkUrl: string) => navigate(linkUrl);

  const { normalLinks, groupLinks, singleUseLinks, inactiveLinks } = getLinksByType(
    schedulingLinks,
  );

  const mapAsCards = (links: typeof schedulingLinks, linkType: "1:1" | "Group") =>
    links.map(({ schedulingLink: link, linkName, url }) => (
      <LinkAdminCard
        key={url}
        linkName={linkName}
        link={link}
        onChangeActive={onChangeActive(link.slug, linkType, linkName)}
        onClickCard={onClickLinkCard}
        onEditLink={onEditLink}
        onShareTimes={() => handleShareTimes({ ...link, linkName, url })}
        onCloneLink={showClone ? onCloneLink : undefined}
        onDeleteLink={onDeleteLink}
        deleting={deletingLink}
      />
    ));

  return (
    <RouteRenderer loading={false} error={!!error}>
      <TrialUpgradeDialog
        openState={monetizationModalOpen}
        shouldshowJoinExistingPlan={shouldUserSeeJoinExistingPlan}
        onClose={() => {
          setMonetizationModalOpen(null);
        }}
      />
      {shareTimesLink && (
        <ShareBestTimesLinkModal {...shareTimesLink} onClose={() => setShareTimesLink(undefined)} />
      )}
      {currentUserLinkName && (
        <SchedulingLinkSettingsModal
          username={currentUserLinkName}
          open={view === "settings"}
          onClose={() => setView("dashboard")}
        />
      )}
      <div cw-id="scheduling links">
        <div className="cw-flex cw-justify-between cw-mb-3 cw-gap-2">
          <div className="cw-flex cw-flex-wrap cw-gap-x-6">
            <div className="cw-flex cw-items-center cw-gap-1">
              <span className="cw-body-lg cw-font-bold">Username:</span>
              <UsernameDisplayMode username={currentUserLinkName} />
            </div>
            <div className="cw-flex cw-items-center cw-gap-1 cw-body-lg">
              <span className="cw-font-bold">Logo:</span>
              {logoStatus}
            </div>
          </div>
          <Button variant="outlined" startIcon={Settings} onClick={() => setView("settings")}>
            View settings
          </Button>
        </div>

        {showLinksMonetizationBanner && (
          <div className="cw-mb-2">
            <PaywallBannerUpgradeCta
              showJoinExistingPlan={shouldUserSeeJoinExistingPlan}
              bannerType="LinksSingleUserFree"
              pricingTracking={() =>
                track(TrackingEvents.PAYWALLS.M2_LINKS_DASHBOARD_PRICING_CLICKED)
              }
              billingTracking={() =>
                track(TrackingEvents.PAYWALLS.M2_LINKS_DASHBOARD_JOIN_PLAN_CLICKED)
              }
            />
          </div>
        )}
        {!showLinksMonetizationBanner && showRoundRobinMonetizationBanner && (
          <div className="cw-mb-2">
            <PaywallBannerUpgradeCta
              showJoinExistingPlan={shouldUserSeeJoinExistingPlan}
              bannerType={"RoundRobin"}
              pricingTracking={() =>
                track(TrackingEvents.PAYWALLS.ROUND_ROBIN_DASHBOARD_PRICING_CLICKED)
              }
              billingTracking={() =>
                track(TrackingEvents.PAYWALLS.ROUND_ROBIN_DASHBOARD_JOIN_PLAN_CLICKED)
              }
            />
          </div>
        )}
        <div className="cw-flex cw-flex-col cw-gap-5">
          <div className="cw-flex cw-flex-col cw-gap-2">
            <h1 className="cw-heading-2xl">Links</h1>
            <div
              className="cw-grid cw-gap-8 cw-mb-8"
              style={{ gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))" }}
            >
              <CreateLinkCard onClick={() => onCreateLink(false)} />
              {loading && <LinksDashboardLoadingCard />}
              {mapAsCards(normalLinks, "1:1")}
            </div>
          </div>
          <div className="cw-flex cw-flex-col cw-gap-2">
            <div className="cw-flex cw-gap-2 cw-mb-2">
              <h1 className="cw-heading-2xl">Group links</h1>
              {canUserSeeLinksTeamsBadge && (
                <TrialProBadgeWithTooltip
                  tooltipType={getCopyForMonetizationBadges("Group Links")}
                  showToolTip={true}
                  daysLeftOnTrial={daysLeftInTrial || undefined}
                  badgeText="Teams"
                />
              )}
            </div>

            <div
              className="cw-grid cw-gap-8 cw-mb-8"
              style={{ gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))" }}
            >
              <CreateLinkCard
                onClick={() => {
                  onCreateLink(true);
                }}
                variant="group"
              />
              {mapAsCards(groupLinks, "Group")}
            </div>
          </div>
          <div className="cw-flex cw-flex-col cw-gap-2">
            <h1 className="cw-heading-2xl cw-mb-2">Single-use links</h1>
            <div
              className="cw-grid cw-gap-8 cw-mb-8"
              style={{ gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))" }}
            >
              <CreateLinkCard
                onClick={() => {
                  onCreateLink(false, true);
                }}
                variant="single-use"
              />
              {loading && <LinksDashboardLoadingCard />}
              {mapAsCards(singleUseLinks, "1:1")}
            </div>
          </div>
          {!!inactiveLinks.length && (
            <div className="cw-flex cw-flex-col cw-gap-2">
              <h1 className="cw-heading-2xl cw-mb-2" aria-label="Inactive Links">
                Inactive
              </h1>
              <div
                className="cw-grid cw-gap-8 cw-mb-8"
                style={{ gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))" }}
              >
                {loading && <LinksDashboardLoadingCard />}
                {mapAsCards(inactiveLinks, "1:1")}
              </div>
            </div>
          )}
        </div>
      </div>
    </RouteRenderer>
  );
}
