import { Skeleton } from "@clockwise/design-system";
import { TradeoffType } from "@clockwise/schema/v2";
import { useGatewayMutation } from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import classNames from "classnames";
import { isEmpty } from "lodash";
import React, { useCallback } from "react";
import toast from "react-hot-toast";
import { useCurrentProposal } from "../chat-plus-calendar/CurrentProposalContext";
import { TradeoffBlock } from "../chat-plus-calendar/PersistedProposalContext";
import {
  AvailabilityIssue,
  FixableConflict,
  Inconveniences,
  NoIssues,
  WorksForEveryone,
} from "./Tradeoffs";
import { RemoveTradeoffFromProposalDocument } from "./__generated__/RemoveTradeoffFromProposal.v2.generated";
import { getTradeoffBlocksToDisplay } from "./tradeoffs.utils";

const TIME_OPTIONS_SHARED_CLASSES =
  "cw-border-solid cw-font-medium cw-group cw-border cw-px-2.5 cw-py-3 cw-mb-2 cw-rounded-lg cw-flex cw-flex-row cw-justify-between hover:cw-bg-busy-inset hover:cw-bg-opacity-50";

export const TradeoffBlocks = ({
  tradeoffBlocks,
  loading,
}: {
  tradeoffBlocks: TradeoffBlock[];
  loading: boolean;
}) => {
  const { currentProposal } = useCurrentProposal();
  const totalAttendeeCount = currentProposal?.attendees.length ?? 0;

  const filteredTradeoffBlocks = getTradeoffBlocksToDisplay(tradeoffBlocks);
  const uniqueTradeoffAttendees = new Set(
    [...filteredTradeoffBlocks].flatMap((tradeoff) =>
      tradeoff.affectedAttendees.map((attendee) => attendee.person.email),
    ),
  );

  const showWorksForEveryone = isEmpty(filteredTradeoffBlocks);
  const showRemaningNoIssues =
    !showWorksForEveryone && totalAttendeeCount > uniqueTradeoffAttendees.size;

  const track = useTracking();
  const [removeTradeoffFromProposal] = useGatewayMutation(RemoveTradeoffFromProposalDocument);

  const handleRemovalOfTradeoff = useCallback(
    (diffId: string) => {
      void removeTradeoffFromProposal({
        variables: { eventDiffId: diffId },
        onCompleted: (data) => {
          toast.success("Will not fix conflict");

          const proposalId = data?.removeTradeoffFromProposal?.id;
          track(TrackingEvents.DIRECT_MANIPULATION.RESCHEDULE_MODAL.TRADEOFF.REMOVED, {
            proposalId,
          });
        },
        onError: () => {
          toast.error("Failed to remove tradeoff");
        },
      });
    },
    [removeTradeoffFromProposal, track],
  );

  if (loading && isEmpty(tradeoffBlocks)) return <TradeoffBlocksSkeleton />;

  return (
    <>
      {showWorksForEveryone && (
        <div className={classNames(TIME_OPTIONS_SHARED_CLASSES, "cw-border-subtle")}>
          <WorksForEveryone />
        </div>
      )}
      {filteredTradeoffBlocks.map((tradeoffBlock, index) => {
        switch (tradeoffBlock.tradeoffType) {
          case TradeoffType.AvailabilityIssue:
            return (
              <div
                className={classNames(TIME_OPTIONS_SHARED_CLASSES, "cw-border-warning")}
                style={{
                  background: "linear-gradient(to right, #fffaeb 0%, #fffefa 74%, #ffffff 90%)",
                }}
              >
                <AvailabilityIssue
                  key={index}
                  tradeoffBlock={tradeoffBlock}
                  showAttendeeCount={true}
                  showBottomBorder={false}
                  loading={loading}
                />
              </div>
            );
          case TradeoffType.FixableConflict:
            return (
              <div
                className={classNames(TIME_OPTIONS_SHARED_CLASSES, "cw-border-busy")}
                style={{
                  background: "linear-gradient(to right, #F7FAFF 0%, #ffffff 100%)",
                }}
              >
                <FixableConflict
                  key={index}
                  tradeoffBlock={tradeoffBlock}
                  showAttendeeCount={true}
                  showBottomBorder={false}
                  onClickX={handleRemovalOfTradeoff}
                  loading={loading}
                />
              </div>
            );
          case TradeoffType.Inconvenience:
            return (
              <div className={classNames(TIME_OPTIONS_SHARED_CLASSES, "cw-border-subtle")}>
                <Inconveniences
                  key={index}
                  tradeoffBlock={tradeoffBlock}
                  showAttendeeCount={true}
                  showBottomBorder={false}
                  loading={loading}
                />
              </div>
            );
          default:
            return null;
        }
      })}
      {showRemaningNoIssues && (
        <div className={classNames(TIME_OPTIONS_SHARED_CLASSES, "cw-border-subtle")}>
          <NoIssues
            attendeeCount={totalAttendeeCount - uniqueTradeoffAttendees.size}
            showAttendeeCount={true}
          />
        </div>
      )}
    </>
  );
};

export const TradeoffBlocksSkeleton = () => {
  return (
    <div className={classNames(TIME_OPTIONS_SHARED_CLASSES, "cw-border-subtle")}>
      <div className="cw-flex cw-flex-col cw-gap-2 cw-w-full">
        <Skeleton variant="rect" width="50%" height={14} className="cw-rounded-full" />
        <Skeleton variant="rect" width="75%" height={14} className="cw-rounded-full" />
        <Skeleton variant="rect" width="60%" height={14} className="cw-rounded-full" />
      </div>
    </div>
  );
};
