import { SuggestedTimeOption } from "#webapp/src/components/chat-plus-calendar/PersistedProposalContext";
import { Skeleton } from "@clockwise/design-system";
import { AccessTimeFilled } from "@clockwise/design-system/icons";
import { ProposalState } from "@clockwise/schema/v2";
import { PersonRounded } from "@material-ui/icons";
import classNames from "classnames";
import { DateTime, Interval } from "luxon";
import { default as React } from "react";
import { Attendee } from "../../proposals/utils/attendee";
import { ECTitle } from "../ECTitle";
import { TimeSuggestionOption } from "./TimeSuggestionOption";
import { TimeSuggestionsAttendeePicker } from "./TimeSuggestionsAttendeePicker";
import { TimeSuggestionsDurationPicker } from "./TimeSuggestionsDurationPicker";
import { TimeSuggestionsSearchTimeRangePicker } from "./TimeSuggestionsSearchTimeRangePicker";

const LoadingSkeleton = () => {
  return (
    <div className="cw-px-3">
      {[1, 2, 3].map((index) => (
        <div key={index} className="cw-mb-2 cw-h-[56px]">
          <Skeleton className="cw-h-full" variant="rounded" />
        </div>
      ))}
    </div>
  );
};

export const TimeSuggestions = ({
  title,
  anchorDate,
  attendees,
  suggestedTimeOptions,
  selectedInterval,
  proposalStatus,
  readOnly,
  onChooseTime,
  onUpdateTime,
  onUpdateTitle,
  onHoverTimeSuggestion,
  onRemoveAttendee,
  onAddAttendee,
  onUpdateAttendeeOptionality,
  permissions,
  attendeesOmitted,
  searchTimeRanges,
  onUpdateSearchTimeRanges,
}: {
  title: string;
  anchorDate: DateTime;
  attendees: Attendee[];
  proposalStatus: ProposalState;
  selectedInterval: Interval;
  suggestedTimeOptions: SuggestedTimeOption[];
  searchTimeRanges: Interval[];
  readOnly: boolean;
  onChooseTime: () => void;
  onUpdateTime: (interval: Interval) => void;
  onHoverTimeSuggestion: (interval: Interval | null) => void;
  onUpdateTitle: (title: string) => void;
  onRemoveAttendee: (email: string) => void;
  onAddAttendee: (attendee: Attendee) => void;
  onUpdateAttendeeOptionality: (email: string, isOptional: boolean) => void;
  onUpdateSearchTimeRanges: (intervals: Interval[]) => void;
  permissions: {
    canModify: boolean;
    canInviteOthers: boolean;
    canSeeOtherGuests: boolean;
  };
  attendeesOmitted: boolean;
}) => {
  const loading = proposalStatus !== ProposalState.Active;

  // Only render the 3 best options returned to us by scheduler
  const timeOptions = suggestedTimeOptions.slice(0, 3);

  return (
    <div className="cw-space-y-2 cw-pb-2">
      <ECTitle
        eventName={title}
        readOnly={readOnly}
        onEventNameChange={(e) => {
          onUpdateTitle(e.target.value);
        }}
      />
      <div className={classNames("cw-py-2 cw-px-3", "cw-border")}>
        <div className={classNames("cw-flex cw-flex-row cw-items-center cw-gap-3", "cw-mb-2")}>
          <div>
            <PersonRounded className={classNames("cw-w-4 cw-h-4", "cw-text-subtle")} aria-hidden />
          </div>
          <TimeSuggestionsAttendeePicker
            attendees={attendees}
            permissions={permissions}
            attendeesOmitted={attendeesOmitted}
            readOnly={!permissions.canInviteOthers}
            onAddAttendee={onAddAttendee}
            onRemoveAttendee={onRemoveAttendee}
            onUpdateAttendeeOptionality={onUpdateAttendeeOptionality}
          />
        </div>
        <div className={classNames("cw-flex cw-flex-row cw-items-center cw-gap-3")}>
          <div>
            <AccessTimeFilled
              className={classNames("cw-w-4 cw-h-4", "cw-text-subtle")}
              aria-hidden
            />
          </div>
          <div className={classNames("cw-flex cw-flex-row cw-items-center cw-gap-1")}>
            <TimeSuggestionsDurationPicker />
            <TimeSuggestionsSearchTimeRangePicker
              anchorDate={anchorDate}
              searchTimeRanges={searchTimeRanges}
              onUpdateSearchTimeRanges={onUpdateSearchTimeRanges}
            />
          </div>
        </div>
      </div>
      <div
        className={classNames(
          "cw-flex cw-items-center cw-justify-between",
          "cw-py-2 cw-px-3 cw-mb-2",
          "cw-body-sm cw-font-semibold cw-text-subtle",
        )}
      >
        Results
      </div>
      {loading ? (
        <LoadingSkeleton />
      ) : timeOptions.length === 0 ? (
        <div className="cw-px-3 cw-body-sm cw-text-subtle cw-italic">No results found</div>
      ) : (
        <>
          {timeOptions.map(({ interval, tradeoffBlocks }) => (
            <TimeSuggestionOption
              isSelected={interval.equals(selectedInterval)}
              interval={interval}
              tradeoffBlocks={tradeoffBlocks}
              onChooseTime={onChooseTime}
              onUpdateTime={onUpdateTime}
              onHoverTimeSuggestion={onHoverTimeSuggestion}
              permissions={permissions}
              proposalStatus={proposalStatus}
            />
          ))}
        </>
      )}
    </div>
  );
};
