import { useFeatureFlag } from "#webapp/src/launch-darkly/useLaunchDarkly";
import { removeCalendarIds } from "#webapp/src/state/actions/multi-calendar.actions";
import { isImpersonated } from "@clockwise/client-commons/src/util/jwt";
import { Divider } from "@clockwise/design-system";
import { EcosystemEnum } from "@clockwise/schema";
import { ProposalState } from "@clockwise/schema/v2";
import { useUpdateActiveEvent } from "@clockwise/web-commons/src/util/ActiveEventContext";
import { TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import { useEcosystem } from "@clockwise/web-commons/src/util/ecosystem";
import { jwt } from "@clockwise/web-commons/src/util/local-storage";
import { getRenderTimeZone } from "@clockwise/web-commons/src/util/time-zone.util";
import { noop } from "lodash";
import { Interval } from "luxon";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  DEFAULT_PERMISSIONS,
  useCurrentProposal,
  useUpdateCurrentProposal,
} from "../../chat-plus-calendar/CurrentProposalContext";
import {
  usePersistedProposal,
  useUpdatePersistedProposal,
} from "../../chat-plus-calendar/PersistedProposalContext";
import { useUpdateHoverEvent } from "../../chat-plus-calendar/util/HoverEventContext";
import { useUpdateProposalOptionsOverlayV2 } from "../../chat-plus-calendar/util/ProposalOptionsOverlayContextV2";
import { TradeoffBlocks } from "../../tradeoffs/TradeoffBlocks";
import { useGatewayEventDetails } from "../../web-app-calendar/hooks/useGatewayEventDetails";
import { RSVPButtons } from "../../web-app-calendar/rsvp-buttons";
import { CardWrapper } from "../CardWrapper";
import { EventCategorySelector } from "../EventCategorySelector";
import { ECAgenda } from "../molecules/ECAgenda";
import { ECCalendarSelect } from "../molecules/ECCalendarSelect";
import { ECHistory } from "../molecules/ECHistory";
import { ECLocation } from "../molecules/ECLocation";
import { ECRecurrence } from "../molecules/ECRecurrence";
import { ECHeadTab, ECTabGroup, ECTabList, ECTabPanel, ECTabPanels } from "../molecules/ECTabs";
import { ECTitle } from "../molecules/ECTitle";
import { ECVisibilitySettings } from "../molecules/ECVisibilitySettings";
import { TimeSuggestions } from "../molecules/find-time/TimeSuggestions";
import { toECTransparency } from "../utils/toECTransparency";
import { toECVisibility } from "../utils/toECVisibility";
import { AllDayToggle } from "./AllDayToggle";
import { AttendeePanel } from "./AttendeePanel";
import { ConferenceTypeSelector } from "./ConferenceTypeSelector";
import { MoreEventTimeOptions, MoreSettings } from "./MoreOptions";
import { ProposalFooter } from "./ProposalFooter";
import { ProposalRefine } from "./ProposalRefine";
import { TimeIntervalAndFlexSelector } from "./TimeIntervalAndFlexSelector";

const Header = ({ showTimeSuggestionsTab }: { showTimeSuggestionsTab: boolean }) => {
  return (
    <>
      <ECTabList>
        <ECHeadTab>Event</ECHeadTab>
        {showTimeSuggestionsTab && <ECHeadTab>Find time</ECHeadTab>}
        <ECHeadTab>History</ECHeadTab>
      </ECTabList>
    </>
  );
};

export const ProposalEventCard = () => {
  const ecosystem = useEcosystem();
  const dispatch = useDispatch();
  const titleRef = useRef<HTMLInputElement>(null);
  const track = useTracking();
  const [error, setError] = useState<string | null>(null);
  const { currentProposal } = useCurrentProposal();
  const { status: proposalStatus, tradeoffBlocks, suggestedTimeOptions } = usePersistedProposal();
  const updateActiveEvent = useUpdateActiveEvent();
  const updateHoverEvent = useUpdateHoverEvent();
  const [timeSuggestionsTabEnabled] = useFeatureFlag("TimeSuggestionsTab");
  const { setHoveredSuggestion } = useUpdateProposalOptionsOverlayV2();
  const [tabIndex, setTabIndex] = useState(() => {
    return currentProposal.meta.isFindATime ? 1 : 0;
  });

  const {
    updateTime,
    updateDescription,
    updateAllDay,
    updateTitle,
    addAttendee,
    removeAttendee,
    updateAttendeeOptionality,
    updateRecurrenceRule,
    updateConferenceType,
    updateEventCategory,
    updateLocation,
    initializeFromEvent,
    updateSearchTimeRanges,
  } = useUpdateCurrentProposal();

  const { discardPersistedProposal } = useUpdatePersistedProposal();

  const {
    title,
    description,
    location,
    startTime,
    endTime,
    allDay,
    attendees,
    conferenceType,
    eventId,
    eventCalendarId,
    transparency,
    visibility,
    calendar,
    searchTimeRanges,
  } = currentProposal;

  const { data } = useGatewayEventDetails(eventId, eventCalendarId);

  const {
    setIsEnabled: setOverlayIsEnabled,
    setIsVisible: setOverlayIsVisible,
  } = useUpdateProposalOptionsOverlayV2();

  useEffect(() => {
    if (data?.event && !currentProposal.initialized) {
      const timeRange =
        data.event.dateOrTimeRange.__typename === "DateRange"
          ? data.event.dateOrTimeRange.dateRange
          : data.event.dateOrTimeRange.timeRange;

      const category = data.event.category;

      initializeFromEvent({
        ...data.event,
        category,
        calendarId: data.event.calendar.id,
        dateOrTimeRange: timeRange,
        permissions: data.event.eventPermissions,
        transparency: toECTransparency(data.event.transparency, ecosystem),
        visibility: toECVisibility(data.event.visibility),
      });
    }
  }, [data, initializeFromEvent, currentProposal.initialized]);

  useEffect(() => {
    // Focus the title input when the component mounts
    setTimeout(() => {
      titleRef.current?.focus();
      titleRef.current?.select();
    }, 250);
  }, []);

  useEffect(() => {
    // re-evaluate the tab index if the eventId changes
    setTabIndex(currentProposal.meta.isFindATime ? 1 : 0);
  }, [eventId]);

  const event = data?.event;
  const isOwnEvent = !!event?.eventPermissions.canRemove;

  // We should always have an eventId and eventCalendarId
  // when in an ProposalEventCard, but this appeases TS
  if (!currentProposal?.initialized || !eventId || !eventCalendarId || !event) {
    return <></>;
  }

  const eventTimeRange =
    event.dateOrTimeRange.__typename === "DateRange"
      ? event.dateOrTimeRange.dateRange
      : event.dateOrTimeRange.timeRange;
  const zone = getRenderTimeZone();
  const eventStartTime = Interval.fromISO(eventTimeRange, { zone }).start;

  const isImpersonatedUser = isImpersonated(jwt.get());

  const permissions = data?.event?.eventPermissions ?? DEFAULT_PERMISSIONS;
  const readOnly = !permissions.canModify || isImpersonatedUser;
  const isDescriptionOmitted = data?.event?.isDescriptionOmitted;
  const areAttendeesOmitted = !!data?.event?.areAttendeesOmitted;
  const canRSVP = !!permissions.canRSVP;

  const showTimeSuggestionsTab = !readOnly && timeSuggestionsTabEnabled;
  const isShowingTimeSuggestionsTab = showTimeSuggestionsTab && tabIndex === 1;

  const handleClose = () => {
    discardPersistedProposal([
      () => {
        updateActiveEvent(null);
        updateHoverEvent(null);
      },
      () => {
        setOverlayIsEnabled(false);
        dispatch(removeCalendarIds(attendees.map((a) => a.person.email)));
      },
    ]);
  };

  const onTabChange = (index: number) => {
    setTabIndex(index);
    // if the timeSuggestionsTabEnabled and the index is 1, then we are on the time suggestions tab
    if (timeSuggestionsTabEnabled && index === 1) {
      setOverlayIsEnabled(true);
      setOverlayIsVisible(true);
    } else {
      setOverlayIsEnabled(false);
    }
  };

  return (
    <ECTabGroup defaultIndex={0} selectedIndex={tabIndex} onChange={onTabChange}>
      <CardWrapper
        onClose={handleClose}
        shouldShowRSVP={canRSVP}
        refineInput={<ProposalRefine />}
        rsvpModule={
          canRSVP &&
          event?.responseStatus && (
            <RSVPButtons
              externalEventId={eventId}
              calendarId={eventCalendarId}
              responseState={event?.responseStatus}
              toastOptions={{
                title: event?.title ?? "",
              }}
              variant="expanded"
            />
          )
        }
        header={<Header showTimeSuggestionsTab={showTimeSuggestionsTab} />}
        footer={
          <ProposalFooter
            disabled={
              !!error ||
              (ecosystem === EcosystemEnum.Microsoft && currentProposal.attendees.length === 0) ||
              (isShowingTimeSuggestionsTab && proposalStatus === ProposalState.LoadingSuggestions)
            }
            permissions={permissions}
            isDescriptionOmitted={isDescriptionOmitted}
            recurrenceRule={currentProposal.recurrenceRule}
          />
        }
      >
        {/* TODO: Display warning message */}
        <ECTabPanels>
          <ECTabPanel className="cw-pb-8 cw-space-y-2">
            <ECTitle
              eventName={title}
              readOnly={readOnly}
              onEventNameChange={(e) => {
                updateTitle(e.target.value);
              }}
              ref={titleRef}
            />
            <AttendeePanel
              attendees={attendees}
              permissions={permissions}
              attendeesOmitted={areAttendeesOmitted}
              readOnly={!permissions.canInviteOthers}
              onAddAttendee={addAttendee}
              onRemoveAttendee={removeAttendee}
              onUpdateAttendeeOptionality={updateAttendeeOptionality}
            />
            <div className="cw-mr-4 cw-ml-10">
              <Divider />
            </div>
            <TimeIntervalAndFlexSelector
              startTime={startTime}
              endTime={endTime}
              isAllDay={allDay}
              eventCalendarId={eventCalendarId}
              eventId={eventId}
              readOnly={readOnly}
              onChangeTime={(newStart, newEnd) => {
                if (!newStart || !newEnd) return;
                track(TrackingEvents.DIRECT_MANIPULATION.NEW_EVENT_CARD.UPDATED_TIME, {
                  eventId,
                  eventCalendarId,
                  startTime: newStart,
                  endTime: newEnd,
                });
                updateTime(newStart, newEnd);
              }}
              onError={setError}
            />
            <MoreEventTimeOptions recurrenceRule={currentProposal.recurrenceRule} isAllDay={allDay}>
              <ECRecurrence
                recurrenceRule={currentProposal.recurrenceRule}
                date={startTime.toISODate()}
                onSelect={updateRecurrenceRule}
                readOnly={readOnly}
                originalRecurrence={null}
              />
              <AllDayToggle isAllDay={allDay} onChange={updateAllDay} readOnly={readOnly} />
            </MoreEventTimeOptions>

            {/* TODO: eventually we should handle events with no attendees on them (eg. holds created in gcal) */}
            {attendees.length > 0 && proposalStatus && permissions.canModify && (
              <div className="cw-ml-[2.5rem] cw-mr-3">
                <TradeoffBlocks
                  tradeoffBlocks={tradeoffBlocks ?? []}
                  loading={proposalStatus === ProposalState.LoadingSuggestions}
                />
              </div>
            )}
            <MoreSettings>
              {/* TODO: display calendar info if others' event */}
              {isOwnEvent && currentProposal.eventCategory && (
                <EventCategorySelector
                  category={currentProposal.eventCategory}
                  eventTitle={event?.title}
                  externalEventId={eventId}
                  readOnly={readOnly}
                  onCategoryChange={updateEventCategory}
                />
              )}
              <ConferenceTypeSelector
                selected={conferenceType}
                onSelect={updateConferenceType}
                readOnly={readOnly}
              />
              <ECLocation
                readonly={readOnly}
                location={location ?? ""}
                onLocationChange={(location) => {
                  updateLocation(location ?? "");
                }}
              />
              <ECVisibilitySettings
                readonly={true}
                visibility={visibility}
                transparency={transparency}
                onVisibilityChange={noop}
                onTransparencyChange={noop}
              />
              <ECAgenda
                agenda={description}
                onChangeAgenda={(updatedDescription) => {
                  updateDescription(updatedDescription);
                }}
                readOnly={readOnly}
              />
              {calendar && <ECCalendarSelect calendar={calendar} readOnly />}
            </MoreSettings>
          </ECTabPanel>
          {showTimeSuggestionsTab && (
            <ECTabPanel>
              <TimeSuggestions
                anchorDate={eventStartTime}
                title={title}
                proposalStatus={proposalStatus}
                selectedInterval={Interval.fromDateTimes(startTime, endTime)}
                suggestedTimeOptions={suggestedTimeOptions ?? []}
                readOnly={readOnly}
                onUpdateTitle={updateTitle}
                onUpdateTime={(interval: Interval) => {
                  updateTime(interval.start, interval.end);
                }}
                onHoverTimeSuggestion={(interval: Interval | null) => {
                  setHoveredSuggestion(interval?.toISO() ?? null);
                }}
                onChooseTime={() => {
                  setTabIndex(0);
                }}
                searchTimeRanges={searchTimeRanges ?? []}
                onUpdateSearchTimeRanges={updateSearchTimeRanges}
                attendees={attendees}
                onAddAttendee={addAttendee}
                onRemoveAttendee={removeAttendee}
                onUpdateAttendeeOptionality={updateAttendeeOptionality}
                permissions={permissions}
                attendeesOmitted={areAttendeesOmitted}
              />
            </ECTabPanel>
          )}
          <ECTabPanel>
            <ECHistory eventId={eventId} calendarId={eventCalendarId} />
          </ECTabPanel>
        </ECTabPanels>
      </CardWrapper>
    </ECTabGroup>
  );
};
