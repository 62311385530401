import { frequencyToRecurrenceTextMap } from "@clockwise/client-commons/src/datatypes/RecurrenceRule";
import { TranslationContext } from "@clockwise/client-commons/src/providers/TranslationContext";
import { Skeleton, Switch, Tooltip } from "@clockwise/design-system";
import { Info } from "@clockwise/icons";
import { EventTableRow } from "@clockwise/web-commons/src/components/EventTable";
import { AttendeeAvatar } from "@clockwise/web-commons/src/ui/AttendeeAvatar";
import { AttendeeAvatarStack } from "@clockwise/web-commons/src/ui/AttendeeAvatarStack";
import {
  DisabledWrapper,
  flexibilityExpiredTooltipCopy,
} from "@clockwise/web-commons/src/ui/disabled-wrapper";
import { noop, range } from "lodash";
import React from "react";
import { useMonetization } from "../../../hooks/useMonetization/useMonetization";
import { EventForFlexibility } from "../util/parseFlexibility";
import { FlexEventMenu } from "./FlexEventMenu";

export const Async = ({
  loadingTypes,
  loading,
  rowCount = 3,
  children,
  columnSizing,
}: {
  loadingTypes: ("text" | "avatar")[];
  loading: boolean;
  rowCount?: number;
  children: React.ReactNode;
  columnSizing?: (string | null)[];
}) => {
  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child as JSX.Element, { columnSizing: columnSizing });
    }
    return child;
  });

  return loading ? (
    range(rowCount).map((_, index) => (
      <EventTableRow key={`row-loading-${index}`} columnSizing={columnSizing}>
        {loadingTypes.map((type, index) => {
          if (type === "text") {
            return <Skeleton key={`loading-table-${index}`} width="8rem" height="20px" />;
          } else {
            return (
              <div key={`loading-table-${index}`} className="cw-flex cw-items-center">
                <Skeleton className="cw-rounded-full" width="20px" height="20px" />
                <Skeleton className="cw-rounded-full" width="20px" height="20px" />
                <Skeleton className="cw-rounded-full" width="20px" height="20px" />
              </div>
            );
          }
        })}
      </EventTableRow>
    ))
  ) : (
    <>{childrenWithProps}</>
  );
};

export const FlexEventRow = ({
  onFlexibilityChange = noop,
  updating = false,
  showAttendees = false,
  showNotRecommededBadge = false,
  columnSizing,
  event,
}: {
  onFlexibilityChange?: (eventId: string, isFlexible: boolean) => void;
  updating?: boolean;
  showAttendees?: boolean;
  showNotRecommededBadge?: boolean;
  columnSizing?: (string | null)[];
  event: EventForFlexibility;
}) => {
  const {
    recurrenceType,
    canMarkFlexible,
    eventId,
    isFlexible,
    attendees,
    recurrenceText,
    title,
  } = event;
  const { canUserAccessFlexMeetings } = useMonetization();
  const isHold = attendees?.length <= 1;
  const recurrenceTypeText = recurrenceType
    ? frequencyToRecurrenceTextMap[recurrenceType]
    : "Never";

  const translation = React.useContext(TranslationContext);
  const tooltipText = translation("autopilot-form:toggle-tooltip", { on: !!isFlexible });

  const handleFlexibilityChange = (isFlexible: boolean) => {
    onFlexibilityChange(eventId, isFlexible);
  };

  const restrictedReason = !canMarkFlexible
    ? "You don't have editing rights to this event"
    : !canUserAccessFlexMeetings
    ? flexibilityExpiredTooltipCopy.header
    : undefined;

  return (
    <EventTableRow columnSizing={columnSizing}>
      <div className="cw-flex cw-gap-2 cw-items-center">
        <div className="cw-font-semibold cw-truncate cw-body-sm">{title}</div>
        {showNotRecommededBadge && (
          <Tooltip title={"Flexible meetings work best for meetings with 5 attendees or less"}>
            <div className="cw-text-[10px] cw-font-semibold cw-px-1 cw-text-nowrap cw-rounded-md cw-text-onEmphasis cw-bg-warning-emphasis cw-min-w-fit">
              Not recommended
            </div>
          </Tooltip>
        )}
      </div>
      {showAttendees && (
        <>
          {attendees ? (
            <AttendeeAvatarStack size="large" overlap maxShown={3}>
              {attendees.map((attendee, index) => {
                return <AttendeeAvatar key={index} profile={attendee.person} size="large" />;
              })}
            </AttendeeAvatarStack>
          ) : (
            <div>meeting has no attendees</div>
          )}
        </>
      )}
      <div className="cw-truncate cw-body-sm cw-flex cw-gap-1 cw-items-center">
        {recurrenceTypeText}
        {recurrenceText && (
          <Tooltip title={recurrenceText}>
            <Info className="cw-w-4 cw-h-4 cw-text-muted" />
          </Tooltip>
        )}
      </div>
      <DisabledWrapper
        isDisabled={!canMarkFlexible || (!canUserAccessFlexMeetings && !isHold)}
        tooltipBody={restrictedReason}
      >
        <div className="cw-flex cw-gap-3">
          <Tooltip title={tooltipText}>
            <Switch
              checked={isFlexible}
              disabled={updating}
              onChange={handleFlexibilityChange}
              size="small"
            />
          </Tooltip>
          {isFlexible ? (
            <FlexEventMenu disabled={updating} event={event} />
          ) : (
            <span className="cw-text-muted cw-font-medium cw-body-sm">Mark as Flexible</span>
          )}
        </div>
      </DisabledWrapper>
    </EventTableRow>
  );
};
